import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const EditUserPlanModal = ({ show, onClose, user = {}, onSave }) => {
    const [planType, setPlanType] = useState(user?.planType || '');
    const [planEndDate, setPlanEndDate] = useState(user?.planEndDate || '');

    useEffect(() => {
        if (user) {
            setPlanType(user.planType || '');
            setPlanEndDate(user.planEndDate || '');
        }
    }, [user]);

    const handleSave = () => {
        onSave({ planType, planEndDate });
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Plan de Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" value={user?.name || ''} disabled />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" value={user?.email || ''} disabled />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tipo de Plan</Form.Label>
                        <Form.Control
                            type="number"
                            value={planType}
                            onChange={(e) => setPlanType(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha de Fin de Plan</Form.Label>
                        <Form.Control
                            type="date"
                            value={planEndDate ? new Date(planEndDate).toISOString().split('T')[0] : ''}
                            onChange={(e) => setPlanEndDate(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Guardar Cambios
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditUserPlanModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};

export default EditUserPlanModal;