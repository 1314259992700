// Login.jsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../api/axiosConfig';
import { toast } from 'react-toastify';
import './Login.css';
import Header from "../components/landing/Header";
import Footer from "../components/landing/Footer";

import showIcon from '../assets/show.png';
import hideIcon from '../assets/hide.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'El correo electrónico es obligatorio';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'El correo electrónico no es válido';
    }

    if (!password) {
      newErrors.password = 'La contraseña es obligatoria';
    } else if (password.length < 6) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await api.post('/api/auth/login', { email, password });
        localStorage.setItem('authToken', response.data.token); // Guardar el token en localStorage

        const userResponse = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        });

        const userData = userResponse.data;
        localStorage.setItem('userName', userData.name); // Guardar el nombre del usuario

        toast.success(`Inicio de sesión exitoso! Bienvenido, ${userData.name}`, { position: 'bottom-right', autoClose: 3000 });

        navigate('/dashboard');
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            // Mostrar el toast y luego redirigir
            toast.error('Necesitas verificar tu correo antes de iniciar sesión.', { position: 'bottom-right', autoClose: 3000 });

            // Redirigir después de que el toast haya tenido tiempo de mostrarse
            setTimeout(() => {
              navigate('/verify-email', { state: { email } });
            }, 3500); // 3.5 segundos para asegurarse de que el toast se muestra
          } else if (error.response.status === 400) {
            toast.error('Credenciales incorrectas. Por favor, intenta nuevamente.', { position: 'bottom-right', autoClose: 3000 });
          } else if (error.response.status === 500) {
            toast.error('Error del servidor. Inténtalo más tarde.', { position: 'bottom-right', autoClose: 3000 });
          }
        } else {
          toast.error('Error de red. Por favor, verifica tu conexión.', { position: 'bottom-right', autoClose: 3000 });
        }
      }
    }
  };

  return (
    <div className="login-page">
      <Header />
      <div className="login-container" data-testid="login-container">
        <div className="login-card" data-testid="login-card">
          <h2>Iniciar Sesión</h2>
          <form onSubmit={handleSubmit} data-testid="login-form">
            <div>
              <label>Correo Electrónico</label>
              <input
                data-testid="email-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={errors.email ? 'input-error' : ''}
              />
              {errors.email && <p className="error" data-testid="email-error">{errors.email}</p>}
            </div>
            <div className="password-container">
              <label>Contraseña</label>
              <div className="password-wrapper">
                <input
                  data-testid="password-input"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={errors.password ? 'input-error' : ''}
                />
                <img
                  src={showPassword ? hideIcon : showIcon}
                  alt={showPassword ? 'Hide password' : 'Show password'}
                  className="password-icon"
                  onClick={togglePasswordVisibility}
                  data-testid="toggle-password-visibility"
                />
              </div>
              {errors.password && <p className="error" data-testid="password-error">{errors.password}</p>}
            </div>
            <button data-testid="login-button" type="submit">
              Iniciar Sesión
            </button>
          </form>
          <p>
            ¿Olvidaste tu contraseña? <Link to="/forgot-password" data-testid="forgot-password-link">Recupérala aquí</Link>
          </p>
          <p>
            ¿No tienes una cuenta?{' '}
            <Link to="/signup" data-testid="signup-link">
              Regístrate aquí.
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;