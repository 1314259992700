import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./landingHeader.css";
import BioSafeAppIcon from '../../assets/landingPage/icon512.png';

const LandingHeader = ({ setScrollToSection }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handlePricingClick = (event) => {
        event.preventDefault();
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToSection: "pricing" } }); // Redirige a Home con el estado
        } else if (setScrollToSection) {
            setScrollToSection("pricing"); // Realiza scroll si ya estás en Home
        } else {
            console.error("setScrollToSection is not defined");
        }
    };

    return (
        <header className="header">
            <div className="container">
                <a href="/">
                    <div className="logo">
                        <img src={BioSafeAppIcon} alt="BioSafeApp" />
                        <h1>BioSafeApp</h1>
                    </div>
                </a>
                <nav className="nav">
                    <ul>
                        <li><a href="/about-us">¿Quiénes somos?</a></li>
                        <li>
                            <a href="#pricing" onClick={handlePricingClick}>Precio</a>
                        </li>
                        <li><a href="/contact">¡Contáctanos!</a></li>
                        <li><a href="/login">Login</a></li>
                        <li><a href="/signup" className="register-button">Registrarse</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

LandingHeader.propTypes = {
    setScrollToSection: PropTypes.func.isRequired, // Valida que sea una función y obligatoria
};
export default LandingHeader;