import React from "react";
import "./PricingPlans.css"; // Archivo CSS externo
import api from '../../api/axiosConfig';; // Importa tu configuración global de axios

const PricingPlans = () => {
    const plans = [
        {
            name: "MENSUAL",
            price: 7000,
            duration: 1,
            mainFeature: "Plan básico",
            features: [
                "Pago único",
                "Acceso ilimitado a todas las funcionalidades de la app",
            ],
            isFeatured: false,
            buttonColor: "#3F51B5",
        },
        {
            name: "TRIMESTRAL",
            price: 14990,
            duration: 3,
            mainFeature: "Plan de inicio",
            features: [
                "Pago único",
                "Acceso ilimitado a todas las funcionalidades de la app",
                "Ahorra $6.01 comparado con el plan mensual",
            ],
            isFeatured: false,
            buttonColor: "#64d2c3",
        },
        {
            name: "SEMESTRAL",
            price: 24990,
            duration: 6,
            mainFeature: "Plan profesional",
            features: [
                "Pago único",
                "Acceso ilimitado a todas las funcionalidades de la app",
                "Soporte técnico",
                "1 mes sin cargo (Ahorra $17.01 comparado con el plan mensual)",
            ],
            isFeatured: true,
            buttonColor: "#6c63ff",
        },
        {
            name: "ANUAL",
            price: 44990,
            duration: 12,
            mainFeature: "Plan expansión",
            features: [
                "Pago único",
                "Acceso ilimitado a todas las funcionalidades de la app",
                "Soporte técnico prioritario",
                "2 meses sin cargo (Ahorra $39.01 comparado con el plan mensual)",
                "Participación para elegir funcionalidades futuras",
            ],
            isFeatured: false,
            buttonColor: "#ff7878",
        },
    ];

    const handleSubscribe = async (plan) => {
        try {
            // Enviamos los datos del plan seleccionado al backend utilizando api (axios config)
            const response = await api.post("/api/create-preference", {
                title: plan.name,
                price: plan.price,
                duration: plan.duration
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const { init_point } = response.data;
            // Redirigimos al usuario a la URL de pago
            window.location.href = init_point;
        } catch (error) {
            console.error("Error al crear la preferencia:", error);
            alert("Hubo un problema al iniciar el pago.");
        }
    };

    return (
        <section className="pricing-plans" id="pricing">
            <div className="pricing-container">
                <h2 className="section-heading">
                    Nuestros Planes se Acomodan a <span>Tus Necesidades.</span>
                </h2>
                <p className="section-description">
                    Nuestros planes son flexibles y se adaptan a tus necesidades.
                    <br />
                    Puedes elegir el que más te convenga.
                </p>
                <div className="plans-grid">
                    {plans.map((plan, index) => (
                        <div key={index} className={`plan-card ${plan.isFeatured ? "featured" : ""}`}>
                            {plan.isFeatured && <div className="featured-tag">Más Popular</div>}
                            <h3 className="plan-name">{plan.name}</h3>
                            <p className="plan-price">${plan.price} ARS</p>
                            <p className="plan-duration">
                                {`${plan.duration} ${plan.duration > 1 ? 'Meses' : 'Mes'}`}
                            </p>
                            <hr />
                            <div className="plan-features">
                                <p className="main-feature">{plan.mainFeature}</p>
                                <ul>
                                    {plan.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                            <button
                                onClick={() => handleSubscribe(plan)}
                                className="subscribe-button"
                                style={{ cursor: 'pointer', backgroundColor: plan.buttonColor }}
                            >
                                Suscribirme
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PricingPlans;