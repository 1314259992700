// src/screens/ResetPassword.jsx

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api/axiosConfig';
import './ResetPassword.css';

import showIcon from '../assets/show.png';
import hideIcon from '../assets/hide.png';

const ResetPassword = () => {
  const { token } = useParams(); // Obtener el token de la URL
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  // Validación de contraseñas
  const validatePasswords = () => {
    const newErrors = {};
    const passwordRequirements = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&+])[A-Za-z\d@$!%*?&+]{6,}$/;

    if (!password) newErrors.password = 'La contraseña es obligatoria';
    else if (!passwordRequirements.test(password)) newErrors.password = 'La contraseña debe tener al menos 6 caracteres, incluyendo una letra, un número y un símbolo.';

    if (!confirmPassword) newErrors.confirmPassword = 'La confirmación de la contraseña es obligatoria';
    else if (password !== confirmPassword) newErrors.confirmPassword = 'Las contraseñas no coinciden';

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validatePasswords();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await api.post('/api/auth/reset-password', { token, password });
      toast.success(response.data.message, { position: 'bottom-right', autoClose: 3000 });

      // Esperar 3 segundos antes de redirigir para mostrar el mensaje
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      const message = error.response?.data?.message || 'Error al restablecer la contraseña. Inténtalo más tarde.';
      toast.error(message, { position: 'bottom-right', autoClose: 3000 });
    }
  };

  return (
    <div data-testid="reset-password-container" className="reset-password-container">
      <div data-testid="reset-password-card" className="reset-password-card">
        <h2>Restablecer Contraseña</h2>
        <form data-testid="reset-password-form" onSubmit={handleSubmit}>
          <label>Nueva Contraseña</label>
          <div className="password-container">
            <input
              data-testid="new-password-input"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={errors.password ? 'input-error' : ''}
              placeholder="Ingresa tu nueva contraseña"
            />
            <img
              src={showPassword ? hideIcon : showIcon}
              alt="Toggle Password Visibility"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            />
          </div>
          {errors.password && <p className="error">{errors.password}</p>}

          <label>Confirmar Contraseña</label>
          <div className="password-container">
            <input
              data-testid="confirm-password-input"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={errors.confirmPassword ? 'input-error' : ''}
              placeholder="Confirma tu nueva contraseña"
            />
            <img
              src={showConfirmPassword ? hideIcon : showIcon}
              alt="Toggle Confirm Password Visibility"
              className="toggle-password"
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
          {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}

          <button data-testid="reset-password-button" type="submit">Restablecer Contraseña</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
