import React, { useState, useEffect } from 'react';
import {toast} from 'react-toastify';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../../screen/Establishment.css';
import EstablishmentForm from '../forms/EstablishmentForm';
import api from '../../api/axiosConfig'; // Asegúrate de importar api para hacer requests

// Fix marker icon issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const EstablishmentDetails = () => {
  const { id } = useParams(); // Obtén el ID del establecimiento desde la URL
  const [establishment, setEstablishment] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Verifica si las coordenadas existen
  const hasCoordinates = establishment?.latitude && establishment?.longitude;

  const displayAddress = establishment?.noAddressNumber
    ? `${establishment?.address}, S/N`
    : `${establishment?.address}, ${establishment?.addressNumber}`;

  const googleMapsUrl = hasCoordinates
    ? `https://www.google.com/maps?q=${establishment.latitude},${establishment.longitude}`
    : '#';

  const fetchEstablishment = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstablishment(response.data);
      setError(null);
    } catch (err) {
      setError('No se pudo cargar la información del establecimiento.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishment(); // Llama a la API para cargar los datos del establecimiento
  }, [id]); // Vuelve a cargar los datos cuando cambia el ID

  const handleOpenGoogleMaps = () => {
    if (hasCoordinates) {
      window.open(googleMapsUrl, '_blank');
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = async (updatedData) => {
    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/establishments/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Establecimiento actualizado con éxito');
      setIsEditing(false);
      fetchEstablishment(); // Recarga los datos actualizados
    } catch (error) {
      console.error('Error al actualizar el establecimiento:', error);
      toast.error('Error al actualizar el establecimiento');
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="visits-container">
      <h2>Detalles del Establecimiento</h2>
      <p data-testid="establishment-name"><strong>Nombre:</strong> {establishment.name}</p>
      <p data-testid="establishment-address"><strong>Dirección:</strong> {displayAddress}</p>
      <p data-testid="establishment-neighborhood"><strong>Barrio:</strong> {establishment.neighborhood || 'No disponible'}</p>
      <p data-testid="establishment-city"><strong>Localidad:</strong> {establishment.city}</p>
      <p data-testid="establishment-province"><strong>Provincia:</strong> {establishment.province}</p>
      <p data-testid="establishment-postal-code"><strong>Código Postal:</strong> {establishment.postalCode || 'No disponible'}</p>
      <p data-testid="establishment-industry"><strong>Rubro:</strong> {establishment.industry?.name || 'No disponible'}</p>
      <p data-testid="establishment-contact-info"><strong>Información de Contacto:</strong> {establishment.contactInfo}</p>

      <button data-testid="edit-establishment-button" onClick={handleEditClick} className="edit-establishment-button">
        Editar Establecimiento
      </button>

      <div className="map-container">
        <h3>Ubicación en el mapa</h3>
        {hasCoordinates ? (
          <>
            <MapContainer center={[establishment.latitude, establishment.longitude]} zoom={15} style={{ height: '300px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[establishment.latitude, establishment.longitude]}>
                <Popup>
                  {displayAddress} <br /> {establishment.city}, {establishment.province}
                </Popup>
              </Marker>
            </MapContainer>
            <button data-testid="google-maps-button" onClick={handleOpenGoogleMaps} className="google-maps-button">
              Ver en Google Maps
            </button>
          </>
        ) : (
          <p data-testid="no-coordinates-message" className="no-coordinates-message">
            Para visualizar el mapa, es necesario buscar la dirección exacta del establecimiento
            desde el modal de creación o actualización de establecimiento, disponible en la
            página de establecimientos.
          </p>
        )}
      </div>

      {isEditing && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h4>Editar Establecimiento</h4>
            <EstablishmentForm
              onSubmit={handleSaveChanges}
              initialData={establishment}
              isEditing={true}
            />
            <button className="close-button" onClick={() => setIsEditing(false)}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EstablishmentDetails;