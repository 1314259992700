import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Sidebar from '../components/common/Sidebar';
import AddEstablishmentButton from '../components/establishments/AddEstablishmentButton';
import EstablishmentsList from '../components/establishments/EstablishmentsList';
import PlanExpiredModal from '../components/plans/PlanExpiredModal';
import api from '../api/axiosConfig';
import './Dashboard.css';

const Establishments = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [establishments, setEstablishments] = useState([]);
  const [filteredEstablishments, setFilteredEstablishments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [provinceFilter, setProvinceFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [industryFilter, setIndustryFilter] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [user, setUser] = useState(null);

  const isPlanExpired = user?.planType === 0;

  useEffect(() => {
    document.body.style.overflow = isPlanExpired ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isPlanExpired]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error al cargar los datos del usuario:', error);
        toast.error('Hubo un problema al cargar el perfil del usuario.');
      }
    };

    fetchUserProfile();
    fetchEstablishments();
  }, []);

  const fetchEstablishments = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstablishments(response.data);
      setFilteredEstablishments(response.data);

      const uniqueProvinces = [...new Set(response.data.map(e => e.province))];
      const uniqueCities = [...new Set(response.data.map(e => e.city))];
      const uniqueIndustries = [...new Set(response.data.map(e => e.industry?.name || 'Sin rubro'))];

      setProvinces(uniqueProvinces);
      setCities(uniqueCities);
      setIndustries(uniqueIndustries);
    } catch (error) {
      console.error('Error al cargar los establecimientos:', error);
      toast.error('Hubo un problema al cargar los establecimientos.');
    }
  };

  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sortedEstablishments = [...filteredEstablishments].sort((a, b) => {
      const aValue = field === 'industry' ? a.industry?.name || 'Sin rubro' : a[field];
      const bValue = field === 'industry' ? b.industry?.name || 'Sin rubro' : b[field];
      if (aValue < bValue) return newDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredEstablishments(sortedEstablishments);
  };

  useEffect(() => {
    const filtered = establishments.filter((establishment) => {
      return (
        establishment.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (provinceFilter === '' || establishment.province === provinceFilter) &&
        (cityFilter === '' || establishment.city === cityFilter) &&
        (industryFilter === '' || (establishment.industry?.name === industryFilter || (industryFilter === 'Sin rubro' && !establishment.industry)))
      );
    });
    setFilteredEstablishments(filtered);
  }, [searchTerm, provinceFilter, cityFilter, industryFilter, establishments]);

  const handleEdit = (establishment) => {
    setSelectedEstablishment(establishment);
    setIsEditing(true);
  };

  const handleSaveChanges = async (updatedData) => {
    const confirmUpdate = window.confirm(`¿Estás seguro que quieres editar el establecimiento ${selectedEstablishment.name}?`);
    if (!confirmUpdate) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/establishments/${selectedEstablishment._id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Establecimiento actualizado con éxito');
      fetchEstablishments();
      setIsEditing(false);
      setSelectedEstablishment(null);
    } catch (error) {
      toast.error('Error al actualizar el establecimiento');
      console.error('Error al actualizar el establecimiento:', error);
    }
  };

  const handleDelete = async (establishment) => {
    const confirmDelete = window.confirm(`¿Estás seguro que quieres eliminar el establecimiento ${establishment.name}? Esta acción no se puede deshacer.`);
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.delete(`/api/establishments/${establishment._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Establecimiento eliminado con éxito');
      fetchEstablishments();
    } catch (error) {
      toast.error('Error al eliminar el establecimiento');
      console.error('Error al eliminar el establecimiento:', error);
    }
  };

  if (isPlanExpired) {
    return (
      <PlanExpiredModal
        userName={user?.name || 'Usuario'}
        isPlanExpired={true}
        onClose={() => {}} // Mantener bloqueada la vista
      />
    );
  }

  return (
    <div className="dashboard-container" data-testid="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2>Página de Establecimientos</h2>
        <AddEstablishmentButton
          refreshList={fetchEstablishments}
          initialData={isEditing ? selectedEstablishment : null}
          isEditing={isEditing}
          onSave={handleSaveChanges}
          onCancel={() => setIsEditing(false)}
        />

        <div className="filters-container">
          <input
            type="text"
            placeholder="Buscar por nombre..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <select value={provinceFilter} onChange={(e) => setProvinceFilter(e.target.value)}>
            <option value="">Filtrar por provincia</option>
            {provinces.map((province) => (
              <option key={province} value={province}>
                {province}
              </option>
            ))}
          </select>

          <select value={cityFilter} onChange={(e) => setCityFilter(e.target.value)}>
            <option value="">Filtrar por localidad</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>

          <select value={industryFilter} onChange={(e) => setIndustryFilter(e.target.value)}>
            <option value="">Filtrar por rubro</option>
            {industries.map((industry) => (
              <option key={industry} value={industry}>
                {industry}
              </option>
            ))}
          </select>
        </div>

        <EstablishmentsList
          establishments={filteredEstablishments}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onSort={handleSort}
          sortField={sortField}
          sortDirection={sortDirection}
        />

        {filteredEstablishments.length === 0 && <p>No se encontraron establecimientos.</p>}
      </div>
    </div>
  );
};

export default Establishments;