import React from "react";
import "./ContactUs.css"; // Archivo CSS externo
import LandingHeader from "../components/landing/Header";
import ContactUsImage from "../assets/landingPage/contactus.svg"; // Ajusta la ruta
import Footer from "../components/landing/Footer";
import api from "../api/axiosConfig";

const ContactUs = () => {

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: e.target.message.value,
    };

    try {
      const response = await api.post('/api/contact', formData);

      if (response.status === 200) {
        alert('Mensaje enviado con éxito.');
      } else {
        alert(`Error: ${response.data.message || 'No se pudo enviar el mensaje.'}`);
      }
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      alert('Hubo un problema al enviar tu mensaje.');
    }
  };
  
  return (
    <div className="page-container">
      <LandingHeader />
      <section className="contact-us">
        <div className="contact-us-container">
          <div className="contact-us-content">
            <h2 className="contact-heading">
              No dudes en <span>ponerte en contacto</span> con nosotros.
            </h2>
            <p className="contact-description">
              Estamos aquí para ayudarte. Completa el formulario y te responderemos lo antes posible.
            </p>
            <form className="contact-form" onSubmit={handleSubmit}>
              <input type="text" name="name" placeholder="Nombre completo" className="contact-input" required />
              <input type="email" name="email" placeholder="Tu dirección de correo electrónico" className="contact-input" required />
              <input type="text" name="subject" placeholder="Asunto" className="contact-input" />
              <textarea name="message" placeholder="Tu mensaje aquí" className="contact-textarea" rows="5" required></textarea>
              <button type="submit" className="contact-button">
                Enviar
              </button>
            </form>
          </div>
          <div className="contact-us-image">
            <img
              src={ContactUsImage}
              alt="Contáctanos"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;