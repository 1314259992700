import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomFormModal.css';
import { toast } from 'react-toastify';
import api from '../api/axiosConfig';

const CustomFormModal = ({ formToEdit, onClose, refreshForms }) => {
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState(''); // Añadido el estado para la descripción
  const [formFields, setFormFields] = useState([]);
  const [newOptions, setNewOptions] = useState({}); // Almacena las nuevas opciones por campo

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (formToEdit) {
      setFormTitle(formToEdit.title);
      setFormDescription(formToEdit.description || ''); // Establecer la descripción si está disponible
      setFormFields(
        formToEdit.fields.map((field) => ({
          ...field,
          value: field.type === 'date' && field.value ? formatDate(field.value) : field.value,
        }))
      );
    } else {
      setFormTitle('');
      setFormDescription(''); // Limpiar la descripción cuando no se edita
      setFormFields([]);
    }
  }, [formToEdit]);

  const addField = () => {
    setFormFields([
      ...formFields,
      { label: '', type: 'text', name: `field-${formFields.length}`, options: [], required: false },
    ]);
  };

  const removeField = (index) => {
    setFormFields(formFields.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...formFields];

    if (updatedFields[index].type === 'date' && field === 'value') {
      updatedFields[index][field] = new Date(value).toISOString();
    } else {
      updatedFields[index][field] = value;
    }

    setFormFields(updatedFields);
  };

  const handleNewOptionChange = (index, value) => {
    setNewOptions({
      ...newOptions,
      [index]: value,
    });
  };

  const addOption = (index) => {
    if (newOptions[index]?.trim()) {
      const updatedFields = [...formFields];
      updatedFields[index].options.push(newOptions[index].trim());
      setFormFields(updatedFields);
      setNewOptions({
        ...newOptions,
        [index]: '', // Limpiar el input de la nueva opción solo para el índice actual
      });
    }
  };

  const removeOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...formFields];
    updatedFields[fieldIndex].options.splice(optionIndex, 1);
    setFormFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validación de los campos antes de enviar la solicitud
    if (formFields.some((field) => !field.name.trim() || !field.label.trim())) {
      toast.warning('Todos los campos deben tener un nombre y una etiqueta.');
      return;
    }

    // Validar que los campos de tipo radio y checkbox tengan al menos dos opciones
    if (formFields.some((field) => ['radio', 'checkbox'].includes(field.type) && field.options.length < 2)) {
      toast.warning('Los campos de tipo "radio" y "checkbox" deben tener al menos dos opciones.');
      return;
    }

    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const apiUrl = formToEdit ? `/api/custom-forms/${formToEdit._id}` : '/api/custom-forms';

    const requestBody = {
      title: formTitle,
      description: formDescription, // Añadida la descripción en la petición
      fields: formFields,
      userId,
    };

    try {
      await api({
        method: formToEdit ? 'put' : 'post',
        url: apiUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: requestBody,
      });
      toast.success('Formulario guardado exitosamente');
      refreshForms();
      onClose();
    } catch (error) {
      console.error('Error al guardar el formulario:', error);
      toast.error('Hubo un problema al guardar el formulario');
    }
  };

  return (
    <div className="modal-overlay" onClick={(e) => e.target === e.currentTarget && onClose()} data-testid="custom-form-modal-overlay">
      <div className="custom-form-modal-content">
        <h2>{formToEdit ? 'Editar Formulario' : 'Crear Formulario'}</h2>
        <form onSubmit={handleSubmit}>
          <div className='field-item'>
            <label>
              Título del Formulario:
              <input
                type="text"
                value={formTitle}
                onChange={(e) => setFormTitle(e.target.value)}
                required
                data-testid="form-title-input"
              />
            </label>
            <label>
              Descripción:
              <input
                value={formDescription}
                onChange={(e) => setFormDescription(e.target.value)}
                placeholder="Escribe una breve descripción del formulario"
              />
            </label>
          </div>

          <div className="fields-container">
            {formFields.map((field, index) => (
              <div key={index} className="field-item">
                <label>
                  Campo:
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                    required
                    data-testid={`field-label-${index}`}
                  />
                </label>

                <label>
                  Tipo:
                  <select
                    data-testid={`field-type-${index}`}
                    value={field.type}
                    onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                  >
                    <option value="text">Texto Corto</option>
                    <option value="date">Fecha</option>
                    <option value="radio">Opción única</option>
                    <option value="checkbox">Opciones Múltiples</option>
                    <option value="textarea">Texto Extenso</option>
                  </select>
                </label>

                {['radio', 'checkbox'].includes(field.type) && (
                  <div>
                    <label>Opciones:</label>
                    <ul className="options-list">
                      {field.options.map((option, optionIndex) => (
                        <li key={optionIndex}>
                          {option}
                          <button
                            type="button"
                            className="delete-option-button"
                            onClick={() => removeOption(index, optionIndex)}
                          >
                            Eliminar
                          </button>
                        </li>
                      ))}
                    </ul>
                    <input
                      type="text"
                      value={newOptions[index] || ''}
                      onChange={(e) => handleNewOptionChange(index, e.target.value)}
                      placeholder="Nueva opción"
                      data-testid={`new-option-input-${index}`}
                    />
                    <button
                      type="button"
                      onClick={() => addOption(index)}
                      className="add-option-button"
                      data-testid={`add-option-button-${index}`}
                    >
                      Agregar Opción
                    </button>
                  </div>
                )}

                <label>
                  Obligatorio:
                  <input
                    type="checkbox"
                    checked={field.required}
                    onChange={(e) => handleFieldChange(index, 'required', e.target.checked)}
                    data-testid={`field-required-${index}`}
                  />
                </label>

                <button
                  type="button"
                  className="delete-field-button"
                  onClick={() => removeField(index)}
                >
                  Eliminar Campo
                </button>
              </div>
            ))}
          </div>

          <button type="button" onClick={addField} className="add-field-button" data-testid="add-field-button">
            Agregar Campo
          </button>

          <div className="modal-buttons">
            <button type="submit" className="primary-button" data-testid="submit-form-button">
              {formToEdit ? 'Guardar Cambios' : 'Crear Formulario'}
            </button>
            <button type="button" className="secondary-button" onClick={onClose} data-testid="cancel-form-button">
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CustomFormModal.propTypes = {
  formToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  refreshForms: PropTypes.func.isRequired,
};

export default CustomFormModal;