import React, { useEffect, useState } from 'react';
import api from '../api/axiosConfig';
import { Button, Form, Table, Badge } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import EditUserPlanModal from '../components/admin/EditUserPlanModal';
import UserPlanHistoryModal from '../components/admin/UserPlanHistoryModal';
import './AdminUsersPage.css';
import ExportCSVButton from '../components/admin/ExportCSVButton'

const AdminUsersPage = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const usersPerPage = 50;

    const [emailFilter, setEmailFilter] = useState('');
    const [surnameFilter, setSurnameFilter] = useState('');
    const [selectedPlans, setSelectedPlans] = useState([]);

    const [sortField, setSortField] = useState('planEndDate');
    const [sortDirection, setSortDirection] = useState('desc');

    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [history, setHistory] = useState([]);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    const fetchUserDetails = async (userId) => {
        try {
            const response = await api.get(`/api/admin/users/${userId}`);
            setSelectedUser(response.data);
            setIsModalOpen(true);
        } catch (err) {
            console.error('Error al obtener los datos del usuario:', err);
        }
    };

    const fetchUserHistory = async (userId) => {
        try {
            const response = await api.get(`/api/admin/users/${userId}/history`);
            setHistory(response.data);
            setIsHistoryModalOpen(true);
        } catch (err) {
            console.error('Error al obtener el historial:', err);
            setHistory([]);
            setIsHistoryModalOpen(true);
        }
    };

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const params = {
                email: emailFilter.trim() || undefined,
                surname: surnameFilter.trim() || undefined,
                plan: selectedPlans.length > 0 ? selectedPlans.join(',') : undefined,
                sortField,
                sortDirection,
            };

            const response = await api.get('/api/admin/users', { params });

            if (response.data.users) {
                setAllUsers(response.data.users);
            }
        } catch (err) {
            console.error('Error al obtener los usuarios:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [sortField, sortDirection]);

    const paginatedUsers = allUsers.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
    );

    const handleFilterChange = (e, filterType) => {
        const { value, checked } = e.target;

        switch (filterType) {
            case 'email':
                setEmailFilter(value);
                break;
            case 'surname':
                setSurnameFilter(value);
                break;
            case 'plan':
                setSelectedPlans((prev) =>
                    checked ? [...prev, value] : prev.filter((plan) => plan !== value)
                );
                break;
            default:
                break;
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchUsers();
    };

    const handleClearFilters = () => {
        setEmailFilter('');
        setSurnameFilter('');
        setSelectedPlans([]);
        setSortField('planEndDate');
        setSortDirection('desc');
        fetchUsers();
    };

    const handleSort = (field) => {
        const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
    };

    const renderSortIcon = (field) => {
        if (sortField !== field) return <FaSort />;
        return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
    };

    const renderPaginationButtons = () => {
        const totalPages = Math.ceil(allUsers.length / usersPerPage);
        if (!totalPages) return null;

        const buttons = [];
        const startPage = Math.max(currentPage - 2, 1);
        const endPage = Math.min(currentPage + 2, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <Button
                    key={i}
                    variant={currentPage === i ? 'primary' : 'outline-secondary'}
                    className="mx-1"
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Button>
            );
        }
        return buttons;
    };

    const handleSaveUser = async (updatedFields) => {
        try {
            await api.put(`/api/admin/users/${selectedUser._id}`, updatedFields);
            fetchUsers(); // Refrescar la lista de usuarios después de guardar
            setIsModalOpen(false);
        } catch (err) {
            console.error('Error al actualizar el usuario:', err);
        }
    };

    return (
        <div className="admin-container container mt-4">
            <h1 className="text-center mb-4">Gestión de Usuarios</h1>
            <ExportCSVButton />
            {/* Filtros */}
            <Form className="mb-3">
                <div className="d-flex flex-wrap gap-3">
                    <Form.Group controlId="emailFilter" className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por email"
                            value={emailFilter}
                            onChange={(e) => handleFilterChange(e, 'email')}
                        />
                        <Button variant="primary" className="ms-2" onClick={handleSearch}>
                            Buscar Email
                        </Button>
                    </Form.Group>

                    <Form.Group controlId="surnameFilter" className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por apellido"
                            value={surnameFilter}
                            onChange={(e) => handleFilterChange(e, 'surname')}
                        />
                        <Button variant="primary" className="ms-2" onClick={handleSearch}>
                            Buscar Apellido
                        </Button>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center">
                        <span className="me-2">Plan:</span>
                        {['0', '1', '2', '3', '6', '12'].map((plan) => (
                            <Form.Check
                                inline
                                key={plan}
                                type="checkbox"
                                id={`plan-${plan}`}
                                label={plan}
                                value={plan}
                                checked={selectedPlans.includes(plan)}
                                onChange={(e) => handleFilterChange(e, 'plan')}
                            />
                        ))}
                        <Button variant="primary" className="ms-2" onClick={handleSearch}>
                            Filtrar Plan
                        </Button>
                    </Form.Group>

                    <Button variant="warning" className="ms-2" onClick={handleClearFilters}>
                        Limpiar Filtros
                    </Button>
                </div>
            </Form>

            {/* Tabla */}
            <div className="table-responsive">
                <Table striped bordered hover>
                    <thead className="table-dark">
                        <tr>
                            <th onClick={() => handleSort('name')}>Nombre {renderSortIcon('name')}</th>
                            <th onClick={() => handleSort('surname')}>Apellido {renderSortIcon('surname')}</th>
                            <th onClick={() => handleSort('email')}>Email {renderSortIcon('email')}</th>
                            <th onClick={() => handleSort('planType')}>Plan {renderSortIcon('planType')}</th>
                            <th onClick={() => handleSort('planEndDate')}>
                                Fecha Fin del Plan {renderSortIcon('planEndDate')}
                            </th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    Cargando usuarios...
                                </td>
                            </tr>
                        ) : paginatedUsers.length > 0 ? (
                            paginatedUsers.map((user) => (
                                <tr key={user._id}>
                                    <td>{user.name || 'Sin nombre'}</td>
                                    <td>{user.surname || 'Sin apellido'}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <Badge bg="info">{user.planType}</Badge>
                                    </td>
                                    <td>
                                        {user.planEndDate
                                            ? new Date(new Date(user.planEndDate).setDate(new Date(user.planEndDate).getDate() + 1))
                                                .toLocaleDateString('es-AR', {
                                                    timeZone: 'America/Argentina/Buenos_Aires',
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })
                                            : 'Sin fecha'}
                                    </td>
                                    <td>
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => fetchUserDetails(user._id)}
                                        >
                                            Editar
                                        </Button>
                                        <Button
                                            variant="info"
                                            size="sm"
                                            onClick={() => fetchUserHistory(user._id)}
                                        >
                                            Ver Historial
                                        </Button>
                                        <Button variant="danger" size="sm">
                                            Eliminar
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    No hay usuarios para mostrar.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            {/* Modal de edición */}
            {selectedUser && (
                <EditUserPlanModal
                    user={selectedUser}
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSaveUser}
                />
            )}

            {/* Modal de historial */}
            <UserPlanHistoryModal
                show={isHistoryModalOpen} // Verifica que sea true
                onClose={() => setIsHistoryModalOpen(false)}
                history={history}
            />

            {/* Paginación */}
            <div className="pagination-section d-flex justify-content-center align-items-center mt-3">
                <Button
                    variant="secondary"
                    className="me-2"
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                >
                    Primera
                </Button>
                <Button
                    variant="secondary"
                    className="me-2"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Anterior
                </Button>
                {renderPaginationButtons()}
                <Button
                    variant="secondary"
                    className="ms-2"
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(allUsers.length / usersPerPage)))}
                    disabled={currentPage === Math.ceil(allUsers.length / usersPerPage)}
                >
                    Siguiente
                </Button>
                <Button
                    variant="secondary"
                    className="ms-2"
                    onClick={() => setCurrentPage(Math.ceil(allUsers.length / usersPerPage))}
                    disabled={currentPage === Math.ceil(allUsers.length / usersPerPage)}
                >
                    Última
                </Button>
            </div>
        </div>
    );
};

export default AdminUsersPage;