import React from 'react';
import PropTypes from 'prop-types';
import './UserPlanHistoryModal.css';

const UserPlanHistoryModal = ({ show, onClose, history }) => {
    const handleOverlayClick = (e) => {
        // Cierra el modal solo si el clic fue en el overlay, no en el contenedor del modal
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    if (!show) return null; // Evita renderizar el modal si no debe estar visible

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Historial de Cambios</h2>
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    <table className="history-table">
                        <thead>
                            <tr>
                                <th>Plan Anterior</th>
                                <th>Plan Nuevo</th>
                                <th>Fecha Fin Anterior</th>
                                <th>Fecha Fin Nueva</th>
                                <th>Modificado Por</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((log, index) => (
                                <tr key={index}>
                                    <td>{log.changes.oldPlanType}</td>
                                    <td>{log.changes.newPlanType}</td>
                                    <td>{new Date(log.changes.oldPlanEndDate).toLocaleDateString()}</td>
                                    <td>{new Date(log.changes.newPlanEndDate).toLocaleDateString()}</td>
                                    <td>
                                        {log.modifiedBy?.name} ({log.modifiedBy?.email})
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <button className="close-button" onClick={onClose}>
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

UserPlanHistoryModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    history: PropTypes.array.isRequired,
};

export default UserPlanHistoryModal;