import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, NavLink, Routes, Route, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../components/common/Sidebar';
import EstablishmentDetails from '../components/establishments/EstablishmentDetails';
import EstablishmentRate from '../components/establishments/EstablishmentRate';
import EstablishmentWorkingHours from '../components/establishments/EstablishmentWorkingHours';
import EstablishmentVisits from '../components/establishments/EstablishmentVisits';
import FormModal from '../components/forms/FormModal';
import FormsList from '../components/forms/FormsList';
import FormDetails from '../components/forms/FormDetails';
import VisitsInfoSection from '../components/visits/VisitsInfoSection';
import api from '../api/axiosConfig';
import PlanExpiredModal from '../components/plans/PlanExpiredModal';
import '../components/forms/FormModal.css';
import './Establishment.css';

const Establishment = () => {
  const { id } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [forms, setForms] = useState([]);
  const [customForms, setCustomForms] = useState([]);
  const [formToEdit, setFormToEdit] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [workingHours, setWorkingHours] = useState([]);
  const [user, setUser] = useState(null);
  const isFetching = useRef(false);
  const navigate = useNavigate();

  const initialDays = [
    { day: 'Lunes', hours: [], enabled: false },
    { day: 'Martes', hours: [], enabled: false },
    { day: 'Miércoles', hours: [], enabled: false },
    { day: 'Jueves', hours: [], enabled: false },
    { day: 'Viernes', hours: [], enabled: false },
    { day: 'Sábado', hours: [], enabled: false },
    { day: 'Domingo', hours: [], enabled: false },
  ];

  // Estado derivado: el plan está expirado si `user.planType === 0`
  const isPlanExpired = user?.planType === 0;

  // Bloquear scroll si el modal está activo
  useEffect(() => {
    document.body.style.overflow = isPlanExpired ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isPlanExpired]);

  // Obtener datos del usuario
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
        toast.error('Hubo un problema al obtener la información del usuario.');
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  // Obtener datos del establecimiento
  useEffect(() => {
    if (isFetching.current || id === establishment?.id || isPlanExpired) return;

    const fetchEstablishment = async () => {
      try {
        const token = localStorage.getItem('authToken');

        // Carga los detalles del establecimiento
        const establishmentResponse = await api.get(`/api/establishments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEstablishment(establishmentResponse.data);

        // Carga los horarios de atención del establecimiento
        const workingHoursResponse = await api.get(`/api/establishments/${id}/working-hours`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedHours = workingHoursResponse.data.workingHours;

        // Mapear los horarios obtenidos a la estructura inicial
        const updatedHours = initialDays.map(day => {
          const fetchedDay = fetchedHours.find(fh => fh.day === day.day);
          return fetchedDay
            ? { ...day, hours: fetchedDay.intervals || [], enabled: fetchedDay.intervals.length > 0 }
            : day;
        });

        setWorkingHours(updatedHours);
        isFetching.current = true;
      } catch (error) {
        console.error('Error al obtener el establecimiento o los horarios:', error);
        toast.error('Hubo un problema al obtener el establecimiento. Por favor, inténtelo de nuevo.');
      }
    };

    fetchEstablishment();
  }, [id, establishment?.id, isPlanExpired]);

  // Obtener formularios asociados al establecimiento
  const fetchForms = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${id}/checklists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Verifica que los datos de los formularios sean correctos
      setForms(response.data);
    } catch (error) {
      console.error('Error al obtener los formularios:', error);
      toast.error('Hubo un problema al obtener los formularios. Por favor, inténtelo de nuevo.');
    }
  }, [id]);

  const fetchCustomForms = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/custom-forms', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomForms(response.data);
    } catch (error) {
      console.error('Error al obtener las plantillas de formularios personalizados:', error);
      toast.error('Hubo un problema al obtener las plantillas de formularios personalizados.');
    }
  }, []);

  useEffect(() => {
    if (establishment) {
      fetchForms();
    }
  }, [establishment, fetchForms]);

  const handleAddFormClick = () => {
    setFormToEdit(null);
    setShowForm(true);
    fetchCustomForms();
  };

  const handleEditForm = async (form) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${id}/checklists/${form._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFormToEdit(response.data); // Asegúrate de que `response.data` contenga los datos correctos del checklist
      setShowForm(true);
      fetchCustomForms(); // Si necesitas las plantillas de formularios, puedes mantener esta línea
    } catch (error) {
      console.error('Error al obtener el checklist por ID:', error);
      toast.error('Hubo un problema al obtener el checklist. Por favor, inténtelo de nuevo.');
    }
  };

  const handleDeleteForm = async (form) => {
    const confirmed = window.confirm(`¿Estás seguro de que quieres eliminar el formulario "${form.title}"? Esta acción no se puede deshacer.`);
    if (confirmed) {
      try {
        const token = localStorage.getItem('authToken');
        await api.delete(`/api/establishments/${id}/checklists/${form._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchForms();
        toast.success('Formulario eliminado correctamente.', { position: 'bottom-right', autoClose: 3000 });
      } catch (error) {
        console.error('Error al eliminar el formulario:', error);
        toast.error('Hubo un problema al eliminar el formulario.', { position: 'bottom-right', autoClose: 3000 });
      }
    }
  };

  const handleFormCreated = () => {
    setShowForm(false);
    fetchForms();
  };

  const closeModal = () => {
    setShowForm(false);
    setFormToEdit(null);
  };

  const handleViewForm = (form) => {
    setSelectedForm(form);
  };

  const handleBack = () => {
    setSelectedForm(null);
    navigate('checklists');
  };

  const filterCustomFormsByCategory = (customForms, establishment) => {
    if (!establishment?.industry?._id) {
      return customForms.filter(form => form.title === 'RME' || form.title === 'SGC');
    }

    const industryId = establishment.industry._id;

    return customForms.filter(form =>
      form.assignedRubros.some(rubro => rubro._id === industryId) ||
      form.title === 'RME' ||
      form.title === 'SGC'
    );
  };

  if (isPlanExpired) {
    return (
      <PlanExpiredModal
        userName={user?.name || 'Usuario'}
        isPlanExpired={true}
        onClose={() => navigate('/')} // Redirige al inicio
      />
    );
  }

  if (!establishment) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="dashboard-container" data-testid="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <nav className="tabs" data-testid="tabs-navigation">
          <NavLink to="details" activeClassName="active-tab" data-testid="tab-details">
            Detalles del Establecimiento
          </NavLink>
          <NavLink to="checklists" activeClassName="active-tab" data-testid="tab-checklists" onClick={() => setSelectedForm(null)}>
            Formularios
          </NavLink>
          <NavLink to="visits" activeClassName="active-tab" data-testid="tab-visits">
            Visitas
          </NavLink>
        </nav>
        <Routes>
          <Route
            path="details"
            element={
              <div className="details-and-visits" data-testid="details-section">
                <EstablishmentDetails establishment={establishment} data-testid="establishment-details" />
                <EstablishmentRate establishmentId={id} data-testid="establishment-rate" />
                <EstablishmentWorkingHours establishmentId={id} workingHours={workingHours} data-testid="working-hours" />
              </div>
            }
          />
          <Route
            path="checklists"
            element={
              selectedForm ? (
                <FormDetails form={selectedForm} onBack={handleBack} />
              ) : (
                <div>
                  <button className="add-button" onClick={handleAddFormClick} data-testid="add-form-button">
                    <span className="plus-icon">+</span> Agregar Formulario
                  </button>
                  {showForm && (
                    <FormModal
                      establishmentId={id}
                      onFormCreated={handleFormCreated}
                      onClose={closeModal}
                      formToEdit={formToEdit}
                      filteredForms={filterCustomFormsByCategory(customForms, establishment)}
                      data-testid="form-modal"
                    />
                  )}
                  <FormsList
                    forms={forms}
                    onView={handleViewForm}
                    onEdit={handleEditForm}
                    onDelete={handleDeleteForm}
                    data-testid="forms-list"
                  />
                </div>
              )
            }
          />
          <Route
            path="visits"
            element={
              <div className="details-and-visits" data-testid="visits-section">
                <VisitsInfoSection establishmentId={id} data-testid="visits-info-section" />
                <EstablishmentVisits establishmentId={id} data-testid="establishment-visits" />
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Establishment;