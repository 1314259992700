import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PlanExpiredModal.css';
import PropTypes from 'prop-types';

const PlanExpiredModal = ({ userName }) => {
    const [showModal, setShowModal] = useState(true); // Mostrar el modal por defecto
    const navigate = useNavigate();

    useEffect(() => {
        if (!showModal) {
            navigate('/'); // Redirige al Home cuando se cierra el modal
        }
    }, [showModal, navigate]);

    const handleButtonClick = () => {
        setShowModal(false); // Cierra el modal
        setTimeout(() => {
            navigate('/', { state: { scrollToSection: 'pricing' } }); // Desplaza a la sección de precios
        }, 500); // Retraso antes del desplazamiento
    };

    if (!showModal) return null; // No renderiza nada si el modal está cerrado

    return (
        <div className="plan-expired-modal">
            <div className="modal-content">
                <h2>Hola, {userName}</h2>
                <p>
                    Lamentablemente tu plan ha caducado, pero no te preocupes, todos tus
                    datos, personalizaciones y formularios están guardados. Haz clic en
                    el siguiente botón para seleccionar un plan y proceder con la compra.
                </p>
                <button
                    onClick={handleButtonClick}
                    className="select-plan-button"
                >
                    Seleccionar Plan
                </button>
                <p>
                    Gracias, <br /> Equipo de BioSafeApp
                </p>
            </div>
        </div>
    );
};

PlanExpiredModal.propTypes = {
    userName: PropTypes.string.isRequired,
};

export default PlanExpiredModal;