import React, { useState, useEffect } from 'react';
import './Settings.css';
import Sidebar from '../components/common/Sidebar';
import api from '../api/axiosConfig';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Settings = () => {
  const [rubros, setRubros] = useState([]);
  const [newRubro, setNewRubro] = useState('');
  const [editingRubro, setEditingRubro] = useState(null);
  const [editValue, setEditValue] = useState(''); // Almacenar el valor editado temporalmente
  const [establishmentsCount, setEstablishmentsCount] = useState({});

  useEffect(() => {
    fetchRubros();
    fetchEstablishments();
  }, []);

  const fetchRubros = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/rubros', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRubros(response.data);
    } catch (error) {
      console.error('Error al obtener los rubros:', error);
    }
  };

  const fetchEstablishments = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const counts = response.data.reduce((acc, establishment) => {
        const { industry } = establishment;
        if (industry) {
          acc[industry] = (acc[industry] || 0) + 1;
        }
        return acc;
      }, {});

      setEstablishmentsCount(counts);
    } catch (error) {
      console.error('Error al obtener los establecimientos:', error);
    }
  };

  // Función para capitalizar la primera letra
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleAddRubro = async () => {
    const formattedRubro = capitalizeFirstLetter(newRubro.trim());

    if (!formattedRubro) return;

    // Verificar si el rubro ya existe (ignorar mayúsculas/minúsculas)
    if (rubros.some(r => r.name.toLowerCase() === formattedRubro.toLowerCase())) {
      toast.warning('Este rubro ya existe');
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const response = await api.post('/api/rubros', { name: formattedRubro }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRubros([...rubros, response.data]);
      setNewRubro('');
    } catch (error) {
      console.error('Error al agregar rubro:', error);
    }
  };

  const handleDeleteRubro = async (rubroId) => {
    const count = establishmentsCount[rubroId] || 0;
    const confirmation = window.confirm(
      `Tienes ${count} establecimientos que utilizan esta categoría. ¿Estás seguro de eliminarla? Si la eliminas, estos establecimientos perderán la categoría.`
    );

    if (!confirmation) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.delete(`/api/rubros/${rubroId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRubros(rubros.filter((rubro) => rubro._id !== rubroId));
    } catch (error) {
      console.error('Error al eliminar el rubro:', error);
    }
  };

  const handleEditRubro = async (rubroId) => {
    const formattedRubro = capitalizeFirstLetter(editValue.trim());

    if (rubros.some(r => r.name.toLowerCase() === formattedRubro.toLowerCase())) {
      toast.warning('Este rubro ya existe');
      return;
    }

    const confirmation = window.confirm(`¿Estás seguro que quieres modificar el rubro a "${formattedRubro}"?`);

    if (!confirmation) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/rubros/${rubroId}`, { name: formattedRubro }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchRubros();
      setEditingRubro(null); // Dejar de editar después de guardar
    } catch (error) {
      console.error('Error al editar el rubro:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingRubro(null); // Cancelar la edición y ocultar el input
  };

  return (
    <div data-testid="settings-page-container" className="settings-page-container">
      <Sidebar />
      <div data-testid="settings-sidebar" className="settings-sidebar">
        <div className="settings">
          <h1 data-testid="settings-title">Ajustes de rubros</h1>
          <div className="add-rubro">
            <input
              data-testid="add-rubro-input"
              type="text"
              placeholder="Agregar nuevo rubro"
              value={newRubro}
              onChange={(e) => setNewRubro(e.target.value)}
            />
            <button data-testid="add-rubro-button" onClick={handleAddRubro}>Agregar</button>
          </div>

          <Accordion data-testid="rubros-accordion" >
            <AccordionSummary
              data-testid="rubros-accordion-header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rubros-content"
              id="rubros-header"
            >
              <h3 data-testid="rubros-title">Rubros configurados</h3>
            </AccordionSummary>
            <AccordionDetails data-testid="rubros-accordion-details">
              <div data-testid="rubros-list" className="rubros-list">
                {rubros.length === 0 ? (
                  <p data-testid="no-rubros-message">Rubros no configurados</p>
                ) : (
                  rubros.map((rubro) => (
                    <div data-testid="rubro-item-${rubro._id}" key={rubro._id} className="rubro-item">
                      {editingRubro === rubro._id ? (
                        <div data-testid="edit-rubro-section">
                          <input
                            data-testid="edit-rubro-input"
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                          />
                          <button data-testid="save-edit-rubro-button" onClick={() => handleEditRubro(rubro._id)}>Guardar</button>
                          <button data-testid="cancel-edit-rubro-button" onClick={handleCancelEdit}>Cancelar</button>
                        </div>
                      ) : (
                        <>
                          <span data-testid="rubro-name-${rubro._id}">{rubro.name}</span>
                          <div data-testid="rubro-actions-${rubro._id}" className="rubro-actions">
                            <Tooltip title="Editar">
                              <button
                                data-testid="edit-rubro-button-${rubro._id}"
                                onClick={() => {
                                  setEditingRubro(rubro._id);
                                  setEditValue(rubro.name); // Guardar el valor original
                                }}
                              >
                                ✏️
                              </button>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <button data-testid="delete-rubro-button-${rubro._id}" onClick={() => handleDeleteRubro(rubro._id)}>🗑️</button>
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};


export default Settings;