import React, { useState, useEffect } from "react";
import "./AboutUs.css"; // Archivo CSS externo
import BioSafeAppIcon from "../assets/landingPage/icon512.png"; // Ajusta la ruta
import TeamImage from "../assets/landingPage/team.jpg"; // Imagen representativa del equipo
import LandingHeader from "../components/landing/Header"; // Importar componente Header
import Footer from "../components/landing/Footer"; // Importar componente Footer

const AboutUs = () => {
    const [scrollToSection, setScrollToSection] = useState("");
    useEffect(() => {
        if (scrollToSection) {
            const element = document.getElementById(scrollToSection);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start", // Esto asegura que el inicio del elemento quede visible
                });
    
                // Agregamos un pequeño desplazamiento adicional para el header
                const offset = 80; // Altura del header
                window.scrollBy(0, -offset);
    
                setScrollToSection(""); // Resetea el estado después del scroll
            }
        }
    }, [scrollToSection]);
    
    return (
        <section className="about-us">
            <LandingHeader setScrollToSection={setScrollToSection} />

            <div className="about-us-container">
                {/* Encabezado */}
                <h2 className="about-us-section-heading">
                    Sobre <span className="highlight">Nosotros</span>
                </h2>
                <p className="section-description">
                    En Atenea Conocimientos combinamos innovación, educación y tecnología para desarrollar soluciones a la medida de tus necesidades.
                </p>

                {/* Historia */}
                <div className="history">
                    <h3>Nuestra Historia</h3>
                    <p>
                        Fundada por Juan José Torres Zamora, Atenea Conocimientos se ha destacado en el ámbito del QA Automation, educando a miles de estudiantes y desarrollando frameworks a medida para empresas globales. Ahora, damos un paso más en nuestra misión con el desarrollo de software innovador.
                    </p>
                </div>

                {/* Proyecto BioSafeApp */}
                <div className="featured-project">
                    <img src={BioSafeAppIcon} alt="BioSafeApp" className="featured-image" />
                    <div>
                        <h3>Nuestro Primer Proyecto: BioSafeApp</h3>
                        <p>
                            BioSafeApp es una herramienta revolucionaria para la gestión de establecimientos en bromatología. Este sistema puede personalizarse según las necesidades de tu empresa o integrarse con otras soluciones tecnológicas.
                        </p>
                    </div>
                </div>

                {/* Servicios */}
                <div className="services">
                    <h3>Servicios que Ofrecemos</h3>
                    <ul>
                        <li>Desarrollo de sistemas a medida</li>
                        <li>Personalización de BioSafeApp</li>
                        <li>Consultoría en QA y automatización</li>
                    </ul>
                </div>

                {/* Equipo */}
                <div className="team">
                    <h3>Conoce a Nuestro Equipo</h3>
                    <div className="team-grid">
                        <div className="team-member">
                            <img src={TeamImage} alt="Juan José Torres Zamora" />
                            <h4>Juan José Torres Zamora</h4>
                            <p>Fundador y líder técnico</p>
                        </div>
                        <div className="team-member">
                            <img src={TeamImage} alt="Dangelo Ferreri" />
                            <h4>Dangelo Ferreri</h4>
                            <p>Especialista en backend</p>
                        </div>
                        <div className="team-member">
                            <img src={TeamImage} alt="Emanuel Pringles" />
                            <h4>Emanuel Pringles</h4>
                            <p>Diseñador UI/UX</p>
                        </div>
                        <div className="team-member">
                            <img src={TeamImage} alt="Anabel Torres" />
                            <h4>Anabel Torres</h4>
                            <p>QA y soporte al cliente</p>
                        </div>
                    </div>
                </div>

                {/* CTA */}
                <div className="cta">
                    <p>¿Interesado en trabajar con nosotros? ¡Contáctanos para más información!</p>
                    <a className="about-us-a" href="/contact">Contáctanos</a>
                </div>
            </div>
            <Footer />
        </section>

    );
};

export default AboutUs;
