import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../components/landing/Header";
import WelcomeSection from "../components/landing/WelcomeSection";
import WhyChooseUsSection from "../components/landing/WhyChooseUsSection";
import StepsSection from "../components/landing/StepsSection";
import PricingPlans from "../components/landing/PricingPlans";
import FAQsection from "../components/landing/FAQSection";
import Footer from "../components/landing/Footer";

const Home = ({ setScrollToSection }) => {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.scrollToSection) {
            const element = document.getElementById(location.state.scrollToSection);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 500); // Agrega un pequeño retraso para una experiencia más fluida
            }
            window.history.replaceState({}, document.title, '/'); // Limpia el estado
        }
    }, [location]);

    return (
        <>
            <Header setScrollToSection={setScrollToSection} />
            <WelcomeSection />
            <WhyChooseUsSection />
            <StepsSection />
            <div id="pricing">
                <PricingPlans />
            </div>
            <FAQsection />
            <Footer />
        </>
    );
};
Home.propTypes = {
    setScrollToSection: PropTypes.func.isRequired, // Valida que sea una función y obligatoria
};

export default Home;