import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Auth
import ProtectedAdminRoute from './components/auth/ProtectedAdminRoute';

// Headers
import CommonHeader from './components/common/Header';
import LandingHeader from './components/landing/Header';

// Screens
import ProfilePage from './screen/Profile';
import Login from './screen/Login';
import Home from './screen/Home';
import Signup from './screen/Signup';
import Dashboard from './screen/Dashboard';
import Establishments from './screen/Establishments';
import Establishment from './screen/Establishment';
import Settings from './screen/Settings';
import CustomForms from './screen/CustomFormsPage';
import VerifyEmail from './screen/VerifyEmail';
import ForgotPassword from './screen/ForgotPassword';
import ResetPassword from './screen/ResetPassword';
import SuggestedRoutes from './screen/SuggestedRoutes';
import AboutUs from './screen/AboutUs';
import ContactUs from './screen/ContactUs';
import AdminUsersPage from './screen/AdminUsersPage';
import PaymentSuccess from './screen/PaymentSuccess';
import PaymentFailed from './screen/PaymentFailed';
import PaymentPending from './screen/PaymentPending';
// Modals
import PlanExpiredModal from './components/plans/PlanExpiredModal';

// Notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  const userPlanType = JSON.parse(localStorage.getItem('userPlanType')); // Simulación de plan

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (userPlanType === 0) {
    return (
      <PlanExpiredModal
        userName={localStorage.getItem('userName') || 'Usuario'}
        isPlanExpired
      />
    );
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppContent = () => {
  const [scrollToSection, setScrollToSection] = useState(''); // Estado para el scroll
  const location = useLocation();

  const isHomePage = location.pathname === '/';
  const isLandingPage = location.pathname === '/' || location.pathname === '/about-us'; // Header de Landing
  const showCommonHeader =
    location.pathname !== '/login' &&
    location.pathname !== '/signup' &&
    location.pathname !== '/verify-email' &&
    location.pathname !== '/forgot-password' &&
    location.pathname !== '/contact' &&
    location.pathname !== '/about-us' &&
    !location.pathname.includes('/reset-password') &&
    !isLandingPage; // Excluir el Header común en Landing y About Us

  useEffect(() => {
    if (scrollToSection) {
      console.log('Is scrolling to section:', scrollToSection);
      const element = document.getElementById(scrollToSection);
      console.log('Element:', element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollToSection(''); // Resetea el estado después de hacer scroll
      }
    }
  }, [scrollToSection, location]);

  return (
    <div className="App">
      {/* Header específico según la página */}
      {isLandingPage && <LandingHeader setScrollToSection={setScrollToSection} />}
      {showCommonHeader && <CommonHeader />}

      {/* Contenedor de notificaciones de React-Toastify */}
      <ToastContainer
        position="bottom-right"
        style={{ zIndex: 9999, marginTop: showCommonHeader || isHomePage ? '60px' : '0px' }}
      />

      <Routes>
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/failure" element={<PaymentFailed />} />
        <Route path="/pending" element={<PaymentPending />} />
        <Route
          path="/"
          element={<Home setScrollToSection={setScrollToSection} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/about-us"
          element={<AboutUs setScrollToSection={setScrollToSection} />}
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/establecimientos"
          element={
            <PrivateRoute>
              <Establishments />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/establecimiento/:id/*"
          element={
            <PrivateRoute>
              <Establishment />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/custom-forms"
          element={
            <PrivateRoute>
              <CustomForms />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/suggested-routes"
          element={
            <PrivateRoute>
              <SuggestedRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedAdminRoute>
              <AdminUsersPage />
            </ProtectedAdminRoute>
          }
        />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;