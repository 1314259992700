import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VisitDetailsModal.css';
import api from '../../api/axiosConfig';
import AttachFormModal from './AttachFormModal';
import eyeIcon from '../../assets/show.png';
import PDFViewModal from './PDFViewModal.jsx';
import deleteIcon from '../../assets/delete-icon.png';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

const VisitDetailsModal = ({ visit, onClose, onStatusChanged }) => {
  const [paymentStatus, setPaymentStatus] = useState(visit.paymentStatus || 'No Pagado');
  const [newPaymentStatus, setNewPaymentStatus] = useState(paymentStatus);
  const [, setShowSaveButton] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showAttachFormModal, setShowAttachFormModal] = useState(false);
  const [attachedForm, setAttachedForm] = useState(visit.forms || []);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showPDFViewModal, setShowPDFViewModal] = useState(false);
  const [note, setNote] = useState(visit.notes || '');
  const [isEditingNote, setIsEditingNote] = useState(false);

  useEffect(() => {
    // Establecer los valores iniciales cuando la visita cambie
    setPaymentStatus(visit.isPaid ? visit.paymentMethod : 'No Pagado');
    setNewPaymentStatus(visit.isPaid ? visit.paymentMethod : 'No Pagado');
    setAttachedForm(visit.forms || []);
    setNote(visit.notes || '');
  }, [visit]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const isPaid = newPaymentStatus !== 'No Pagado';
      const paymentMethod = newPaymentStatus;

      const token = localStorage.getItem('authToken');

      const updateData = {
        paymentMethod,
        isPaid,
      };

      await api.put(
        `/api/visits/${visit._id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Obtener la visita actualizada
      const response = await api.get(`/api/visits/${visit._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPaymentStatus(newPaymentStatus);
      setShowSaveButton(false);
      toast.success('Información actualizada correctamente');

      if (onStatusChanged) {
        onStatusChanged(response.data); // Pasar la visita actualizada
      }

      onClose(); // Cerrar el modal automáticamente
    } catch (error) {
      console.error('Error al actualizar la información:', error);
      toast.error('Hubo un problema al actualizar la información. Por favor, inténtalo de nuevo.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveNote = async () => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem('authToken');
      await api.put(
        `/api/visits/${visit._id}/note`,
        { note },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Nota actualizada correctamente');
      setIsEditingNote(false);
      if (onStatusChanged) onStatusChanged();
    } catch (error) {
      toast.error('Hubo un problema al actualizar la nota. Por favor, inténtalo de nuevo.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleViewForm = async (form) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${visit.establishmentId._id}/checklists/${form._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedForm(response.data);
      setShowPDFViewModal(true);
    } catch (error) {
      console.error('Error al obtener el formulario:', error);
      toast.error('Hubo un problema al obtener el formulario. Por favor, inténtelo de nuevo.');
    }
  };

  const handleCloseAttachFormModal = async () => {
    setShowAttachFormModal(false);
    const token = localStorage.getItem('authToken');
    const response = await api.get(`/api/visits/${visit._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAttachedForm(response.data.forms || []);
    if (onStatusChanged) {
      onStatusChanged();
    }
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDeleteForm = async (form) => {
    if (!form || !form._id) {
      toast.error('Hubo un problema al encontrar el ID del formulario. Por favor, inténtelo de nuevo.');
      return;
    }

    const confirmed = window.confirm(`¿Estás seguro de que quieres desasociar el formulario "${form.title}" de esta visita?`);
    if (confirmed) {
      try {
        const token = localStorage.getItem('authToken');
        await api.put(`/api/visits/${visit._id}/detachForm/${form._id}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Formulario desasociado de la visita correctamente');
        handleCloseAttachFormModal();
      } catch (error) {
        toast.error('Hubo un problema al desasociar el formulario. Por favor, inténtelo de nuevo.');
      }
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick} data-testid="visit-details-modal-overlay">      
    <div className="modal-content long-modal-content">
      <div className="visit-details-modal-content" data-testid="visit-details-modal-content">
        <h2>Detalles de la Visita</h2>
        <button className="close-button" onClick={onClose} data-testid="close-button">×</button>
      </div>
      <div className="visit-details-modal-content modal-body">
        <p data-testid="visit-date"><strong>Fecha:</strong> {visit?.date ? getFormattedDate(visit.date) : 'Fecha no disponible'}</p>
        <p data-testid="visit-type"><strong>Tipo:</strong> {visit?.type || 'No disponible'}</p>
        <p data-testid="payment-status"><strong>Estado de Pago:</strong> {visit?.isPaid ? 'Pagado' : 'No Pagado'}</p>
        <p data-testid="payment-method"><strong>Establecimiento:</strong> {visit.establishmentId?.name || 'No disponible'}</p>

        <a
          href={`/dashboard/establecimiento/${visit.establishmentId?._id}/visits`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver detalles del establecimiento
        </a>

        <label data-testid="payment-method-label">
          Cambiar Método de Pago:
          <select
            value={newPaymentStatus}
            onChange={(e) => {
              setNewPaymentStatus(e.target.value);
              setShowSaveButton(e.target.value !== paymentStatus);
            }}
            data-testid="payment-method-select"
          >
            {['Efectivo', 'Transferencia', 'No Pagado'].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </label>

        <div className='visit-notes' data-testid="visit-notes">
          <h3>Notas:</h3>
          {isEditingNote ? (
            <>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Escribe una nota"
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <button onClick={handleSaveNote} disabled={isSaving}>
                  {isSaving ? 'Guardando...' : 'Guardar Nota'}
                </button>
                <button onClick={() => setIsEditingNote(false)}>Cancelar</button>
              </div>
            </>
          ) : (
            <>
              <p>{note || 'No hay notas'}</p>
              <button onClick={() => setIsEditingNote(true)}>Editar Nota</button>
            </>
          )}
        </div>

        {attachedForm && attachedForm.length > 0 ? (
          <div className="attached-forms-container" data-testid="attached-forms">
            <h3>Formularios Adjuntos:</h3>
            <table className="styled-table" data-testid="attached-forms-table">
              <thead>
                <tr>
                  <th>Nombre del Formulario</th>
                  <th>Fecha de Creación</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {attachedForm.map((form) => (
                  <tr key={form._id}>
                    <td>{form.title || 'Sin título'}</td>
                    <td>{getFormattedDate(form.createdAt)}</td>
                    <td>
                      <Tooltip title="Ver Formulario">
                        <img
                          src={eyeIcon}
                          alt="Ver Formulario"
                          style={{ maxWidth: '20px', cursor: 'pointer' }}
                          onClick={() => handleViewForm(form)}
                          data-testid={`view-form-button-${form._id}`}
                        />
                      </Tooltip>
                      <Tooltip title="Eliminar Formulario">
                        <img
                          src={deleteIcon}
                          alt="Eliminar Formulario"
                          style={{ maxWidth: '20px', marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => handleDeleteForm(form)}
                          data-testid={`delete-form-button-${form._id}`}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No hay formularios adjuntos.</p>
        )}

        <button onClick={() => setShowAttachFormModal(true)} data-testid="attach-form-button">
          Adjuntar Formulario
        </button>

        {showAttachFormModal && (
          <AttachFormModal
            visit={visit}
            onClose={() => setShowAttachFormModal(false)}
            onFormAttached={() => handleCloseAttachFormModal()}
          />
        )}

        {showPDFViewModal && selectedForm && (
          <PDFViewModal form={selectedForm} onClose={() => setShowPDFViewModal(false)} />
        )}
      </div>
      <div className="modal-footer">
        <button
          data-testid="close-modal-button"
          className="close-button" onClick={onClose}>Cerrar</button>
        <button
          className="save-button"
          onClick={handleSave}
          disabled={isSaving}
          data-testid="save-button"
        >
          {isSaving ? 'Guardando...' : 'Guardar'}
        </button>
      </div>
    </div>
    </div>
  );
};

VisitDetailsModal.propTypes = {
  visit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onStatusChanged: PropTypes.func.isRequired,
};

export default VisitDetailsModal;