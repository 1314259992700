import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/axiosConfig';
import './EstablishmentVisits.css';
import VisitDetailsModal from '../../components/visits/VisitDetailsModal';
import AttachFormModal from '../../components/visits/AttachFormModal';
import deleteIcon from '../../assets/delete-icon.png';
import invoiceIcon from '../../assets/invoice.png';
import viewIcon from '../../assets/show.png';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ReceiptModal from '../../components/visits/ReceiptModal';
import { toast } from 'react-toastify';

const EstablishmentVisits = ({ establishmentId }) => {
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [showAttachFormModal, setShowAttachFormModal] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [receiptVisit, setReceiptVisit] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const visitsPerPage = 3;

  const location = useLocation();

  const fetchVisits = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/visits/establishment/${establishmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVisits(response.data);
    } catch (error) {
      console.error('Error al obtener las visitas:', error);
      toast.error('No se pudieron cargar las visitas.');
    }
  };

  useEffect(() => {
    fetchVisits();
  }, [establishmentId, location.search]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleViewVisit = async (visit) => {
    try {
      console.log('Fetching visit details with ID:', visit._id);
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/visits/${visit._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Verificar que response.data contenga la fecha y otros detalles esperados
      if (!response.data || !response.data._id || !response.data.establishmentId) {
        console.error('Visit data is missing required fields:', response.data);
        toast.error('La visita no contiene datos válidos.');
        return;
      }

      // Establecer la visita seleccionada si todos los campos esperados están presentes
      setSelectedVisit(response.data);
    } catch (error) {
      console.error('Error al obtener los detalles de la visita:', error);
      toast.error('No se pudieron cargar los detalles de la visita.');
    }
  };

  const handleDeleteVisit = async (visitId) => {
    const confirmed = window.confirm('¿Estás seguro de que quieres eliminar esta visita?');
    if (confirmed) {
      try {
        const token = localStorage.getItem('authToken');
        await api.delete(`/api/visits/${visitId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('Visita eliminada correctamente');
        fetchVisits(); // Refrescar las visitas de este establecimiento
        refreshVisits(); // Refrescar el calendario principal (asegúrate de pasar esta función como prop)
      } catch (error) {
        console.error('Error al eliminar la visita:', error);
        toast.error('No se pudo eliminar la visita.');
      }
    }
  };

  const handleCloseVisitDetails = () => {
    setSelectedVisit(null);
  };

  const handleCloseAttachFormModal = () => {
    setShowAttachFormModal(false);
    setSelectedVisit(null);
  };

  const refreshVisits = () => {
    fetchVisits();
  };

  const handleGenerateReceipt = (visit) => {
    setReceiptVisit(visit);
    setShowReceiptModal(true);
  };

  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = visits.slice(indexOfFirstVisit, indexOfLastVisit);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (visits.length === 0) {
    return <p data-testid="no-visits-message">No has cargado ninguna visita para este local todavía.</p>;
  }

  return (
    <div className="visits-list-container">
      <h2>Visitas al Establecimiento</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th data-testid="header-visit-date">Fecha de la Visita</th>
            <th data-testid="header-payment-status">Estado de Pago</th>
            <th data-testid="header-payment-method">Método de Pago</th>
            <th data-testid="header-actions">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentVisits.length > 0 ? (
            currentVisits.map((visit) => (
              <tr key={visit._id}>
                <td data-testid={`visit-date-${visit._id}`}>{formatDate(visit.date)}</td>
                <td data-testid={`payment-status-${visit._id}`}>{visit.isPaid ? 'Pagado' : 'No Pagado'}</td>
                <td data-testid={`payment-method-${visit._id}`}>{visit.paymentMethod ? visit.paymentMethod : 'No Pagado'}</td>
                <td>
                  <div className="action-buttons">
                    <Tooltip title="Ver Visita" arrow>
                      <button data-testid={`view-button-${visit._id}`} className="view-button" onClick={() => handleViewVisit(visit)}>
                        <img src={viewIcon} alt="Ver" style={{ width: '20px' }} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Borrar Visita" arrow>
                      <button data-testid={`delete-button-${visit._id}`} className="view-button" onClick={() => handleDeleteVisit(visit._id)}>
                        <img src={deleteIcon} alt="Eliminar" style={{ width: '20px' }} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Generar Recibo" arrow>
                      <button data-testid={`generate-receipt-button-${visit._id}`} className="invoice-button" onClick={() => handleGenerateReceipt(visit)}>
                        <img src={invoiceIcon} alt="Recibo" style={{ width: '20px' }} />
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No hay visitas registradas para este establecimiento.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: Math.ceil(visits.length / visitsPerPage) }, (_, index) => (
          <button
            data-testid={`pagination-button-${index + 1}`}
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {selectedVisit && !showAttachFormModal && (
        <VisitDetailsModal visit={selectedVisit} onClose={handleCloseVisitDetails} onStatusChanged={refreshVisits} />
      )}

      {showAttachFormModal && selectedVisit && (
        <AttachFormModal
          visit={selectedVisit}
          onClose={handleCloseAttachFormModal}
          onFormAttached={refreshVisits}
        />
      )}
      {showReceiptModal && receiptVisit && (
        <ReceiptModal visit={receiptVisit} onClose={() => setShowReceiptModal(false)} />
      )}
    </div>
  );
};

EstablishmentVisits.propTypes = {
  establishmentId: PropTypes.string.isRequired,
};

export default EstablishmentVisits;