import React from 'react';
import api from '../../api/axiosConfig';

const ExportCSVButton = () => {
    const handleExport = async () => {
        try {
            const response = await api.get('/api/admin/export-users-csv', {
                responseType: 'blob', // Necesario para descargar archivos
            });

            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'usuarios.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Mensaje de confirmación
            alert('El archivo CSV se exportó correctamente.');
        } catch (error) {
            console.error('Error al exportar CSV:', error);
            alert('Hubo un error al exportar el archivo CSV.');
        }
    };

    return (
        <button onClick={handleExport} className="btn btn-success">
            Exportar CSV
        </button>
    );
};

export default ExportCSVButton;