// success.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Cuando countdown llegue a 0, redirigimos
    if (countdown === 0) {
      navigate('/dashboard');
    }

    return () => clearInterval(intervalId);
  }, [countdown, navigate]);

  return (
    <div style={{marginTop:"4rem", textAlign: 'center'}}>
      <h1>¡Pago aprobado con éxito!</h1>
      <p>Serás redirigido a tu panel de control en {countdown} {countdown === 1 ? 'segundo' : 'segundos'}...</p>
    </div>
  );
};

export default SuccessPage;