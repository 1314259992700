import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './FormDetails.css';
import api from '../../api/axiosConfig';

const FormDetails = ({ form, onBack }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');

  // Imagen en blanco predeterminada en Base64
  const blankImageBase64 =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wIAAgEBAWn+hdcAAAAASUVORK5CYII=';

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(
          userData.companyLogo
            ? `${userData.companyLogo}?cacheblock=${new Date().getTime()}`
            : blankImageBase64
        );
        setSignatureImage(
          userData.signatureImage
            ? `${userData.signatureImage}?cacheblock=${new Date().getTime()}`
            : blankImageBase64
        );
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setCompanyLogo(blankImageBase64);
        setSignatureImage(blankImageBase64);
      }
    };

    fetchUserProfile();
  }, []);

  const calculateImageDimensions = ({ width, height }, maxWidth, maxHeight) => {
    const aspectRatio = width / height;
    if (aspectRatio > 1) {
      const finalWidth = maxWidth;
      const finalHeight = maxWidth / aspectRatio;
      return { width: finalWidth, height: Math.min(finalHeight, maxHeight) };
    } else {
      const finalHeight = maxHeight;
      const finalWidth = maxHeight * aspectRatio;
      return { width: Math.min(finalWidth, maxWidth), height: finalHeight };
    }
  };

  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;

      img.onload = () => {
        try {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          const dataURL = canvas.toDataURL('image/png');
          resolve({ base64: dataURL, width: img.width, height: img.height });
        } catch (error) {
          console.error('Error processing image:', error);
          reject(error);
        }
      };

      img.onerror = (error) => {
        console.error('Error loading image:', error);
        reject(error);
      };
    });
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');

    try {
      // Cargar el logo y la firma con imágenes en blanco como fallback
      const logo = await loadImageAsBase64(companyLogo);
      const signature = await loadImageAsBase64(signatureImage);

      // Renderizar el logo
      const { base64: logoBase64, width: logoWidth, height: logoHeight } = logo;
      const { width: scaledLogoWidth, height: scaledLogoHeight } = calculateImageDimensions(
        { width: logoWidth, height: logoHeight },
        99.2,
        27.7
      );

      doc.addImage(logoBase64, 'PNG', 10, 10, scaledLogoWidth, scaledLogoHeight);

      // Contenido principal del PDF
      doc.setFontSize(18);
      doc.text(form.title, 10, 50);

      doc.setFontSize(12);
      doc.text(form.description || 'Sin descripción', 10, 60);

      const tableData = form.fields.map((field) => [
        field._doc?.label || field.label || 'Sin etiqueta',
        field._doc?.value || field.value || 'Sin valor',
      ]);

      doc.autoTable({
        head: [['Campo', 'Valor']],
        body: tableData,
        startY: 70,
      });

      const finalY = doc.lastAutoTable.finalY + 20;

      // Renderizar la firma
      const { base64: signatureBase64, width: sigWidth, height: sigHeight } = signature;
      const { width: scaledSigWidth, height: scaledSigHeight } = calculateImageDimensions(
        { width: sigWidth, height: sigHeight },
        50,
        30
      );

      doc.addImage(signatureBase64, 'PNG', 140, finalY, scaledSigWidth, scaledSigHeight);
      doc.text(`Nombre: ${userName}`, 140, finalY + 35);
      doc.text(`Número de matrícula: ${licenseNumber}`, 140, finalY + 45);

      const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');
      doc.save(`${form.title} ${creationDate}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="form-details">
      <div className="button-container">
        <button data-testid="back-button" onClick={onBack} className="back-button">Volver</button>
        <button data-testid="download-button" onClick={handleDownloadPDF} className="download-button">Descargar</button>
      </div>
      <h2 data-testid="form-title">{form.title}</h2>
      <p data-testid="form-description">{form.description || 'Sin descripción'}</p>

      <table data-testid="form-fields-table" className="form-fields">
        <thead>
          <tr data-testid="form-field-row">
            <th>Campo</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {form.fields.map((field) => (
            <tr key={field._id}>
              <td>{field._doc?.label || field.label || 'Sin etiqueta'}</td>
              <td>{field._doc?.value || field.value || 'Sin valor'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
// Definición de PropTypes para validar las propiedades recibidas
FormDetails.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.null]),
      })
    ).isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default FormDetails;