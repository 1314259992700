import React from "react";
import "./WhyChooseUsSection.css";

// Importar todos los íconos
import RoutesIcon from "../../assets/landingPage/view-routes-icon.svg";
import CustomFormsIcon from "../../assets/landingPage/custom-forms-icon.svg";
import EstablishmentIcon from "../../assets/landingPage/establishment-icon.svg";
import PDFReportIcon from "../../assets/landingPage/pdf-report-icon.svg";
import VisitsIcon from "../../assets/landingPage/visits-icon.svg";
import CreditCardIcon from "../../assets/landingPage/credit-card-icon.svg";

const WhyChooseUsSection = () => {
  const features = [
    {
      icon: RoutesIcon,
      title: "Optimización de rutas",
      description:
        "Planifica tus visitas de manera eficiente con rutas calculadas automáticamente para ahorrar tiempo y recursos.",
    },
    {
      icon: CustomFormsIcon,
      title: "Formularios personalizados",
      description:
        "Crea, asigna y utiliza formularios personalizados para cada establecimiento, adaptados a tus necesidades específicas.",
    },
    {
      icon: EstablishmentIcon,
      title: "Gestión de establecimientos",
      description:
        "Registra y organiza todos tus establecimientos con detalles como dirección, rubro y contacto en un solo lugar.",
    },
    {
      icon: PDFReportIcon,
      title: "Reportes en PDF",
      description:
        "Genera reportes profesionales en PDF con logo, firma y detalles de cada visita realizada.",
    },
    {
      icon: VisitsIcon,
      title: "Programación de visitas",
      description:
        "Configura visitas programadas y periódicas para mantener un seguimiento constante de tus inspecciones.",
    },
    {
      icon: CreditCardIcon,
      title: "Gestión de pagos",
      description:
        "Administra métodos de pago y controla el estado de las visitas con indicadores visuales claros.",
    },
  ];

  return (
    <section className="why-choose-us">
      <div className="container">
        <h2>
          ¿Por qué elegir <span className="highlight">BioSafeApp</span>?
        </h2>
        <p>Obtén los siguientes beneficios al utilizar nuestra app.</p>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              {/* Usamos img para renderizar los íconos */}
              <img src={feature.icon} alt={`${feature.title} icon`} className="icon" />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
