import React, { useState } from "react";
import "./FAQSection.css";

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const faqs = [
    {
      question: "¿Puedo integrar BioSafeApp con otras herramientas como Google Maps?",
      answer:
        "Sí, BioSafeApp permite exportar las rutas optimizadas a Google Maps para facilitar la navegación en tiempo real desde tu dispositivo móvil.",
    },
    {
      question: "¿Cómo configuro visitas periódicas para un establecimiento?",
      answer:
        'Desde el panel de visitas, selecciona el establecimiento y haz clic en "Configurar Visitas Periódicas". Podrás elegir los días y meses en los que deseas programar inspecciones.',
    },
    {
      question: "¿Es posible adjuntar diferentes tipos de formularios a las visitas?",
      answer:
        "Sí, puedes adjuntar formularios personalizados, RME o SGC a las visitas desde la sección de Formularios en cada establecimiento.",
    },
    {
      question: "¿Qué sucede si olvido mi contraseña?",
      answer:
        'Puedes restablecer tu contraseña haciendo clic en "Olvidé mi contraseña" en la pantalla de inicio de sesión. Sigue las instrucciones para crear una nueva contraseña.',
    },
    {
      question: "¿Cómo puedo cambiar la tarifa de visita de un establecimiento?",
      answer:
        'Selecciona el establecimiento desde el panel, haz clic en "Actualizar Tarifa", ingresa el nuevo monto y guarda los cambios.',
    },
    {
      question: "¿Puedo personalizar los reportes en PDF?",
      answer:
        "Sí, los reportes generados incluyen el logo de tu empresa, tu firma y otros detalles relevantes que puedes configurar desde tu perfil.",
    },
    {
      question: "¿Tendrán nuevas funcionalidades en el futuro?",
      answer:
        "¡Claro que sí! Es nuestra prioridad escuchar a nuestros usuarios y es por esto que los usuarios de plan expansión podrán votar sobre cuál es la siguiente funcionalidad a implementar.",
    },
  ];

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="faqcontainer">
        <h2 className="section-heading">
          Tienes más <span className="highlight">Dudas?</span>
        </h2>
        <p className="section-description">
          Nosotros tenemos respuestas, y estas son algunas de las preguntas más frecuentes:
        </p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleQuestion(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {openQuestion === index ? "−" : "+"}
                </span>
              </div>
              {openQuestion === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;