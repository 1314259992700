import React, { useState, useEffect, useRef } from 'react';
import { FaBell } from 'react-icons/fa';
import api from '../../api/axiosConfig';
import './NotificationBell.css';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

const NotificationBell = () => {
    const [notifications, setNotifications] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const dropdownRef = useRef(null); // Referencia al dropdown
    const navigate = useNavigate();

    // Fetch notifications from the backend
    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/notifications/unread', {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Ordenar las notificaciones: las no leídas primero
            const sortedNotifications = response.data.notifications.sort((a, b) => a.isRead - b.isRead);
            setNotifications(sortedNotifications);
            setHasUnreadNotifications(sortedNotifications.some(notification => !notification.isRead));
        } catch (error) {
            console.error('Error al obtener las notificaciones:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const handleBellClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        // Agregar evento de clic al documento
        document.addEventListener('mousedown', handleClickOutside);

        // Limpiar el evento al desmontar el componente
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNotificationClick = async (notification) => {
        try {
            const token = localStorage.getItem('token');
    
            // Marcar como leída si no lo está
            if (!notification.isRead) {
                await api.put(`/api/notifications/${notification._id}/mark-as-read`, {}, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
    
            // Verificar si la URL del establecimiento existe
            if (notification.establishmentId && notification.establishmentId._id) {
                const establishmentUrl = `/dashboard/establecimiento/${notification.establishmentId._id}/details`;
                navigate(establishmentUrl);
            }
    
            // Refrescar la lista después de redirigir
            fetchNotifications();
    
            // Cerrar el dropdown después de hacer clic
            setIsDropdownOpen(false);
        } catch (error) {
            console.error('Error al marcar la notificación como leída:', error);
        }
    };

    // Marcar notificación individual como leída
    const markNotificationAsRead = async (notificationId) => {
        try {
            const token = localStorage.getItem('token');
            await api.put(`/api/notifications/${notificationId}/mark-as-read`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchNotifications();
        } catch (error) {
            console.error('Error al marcar la notificación como leída:', error);
        }
    };

    // Marcar todas las notificaciones como leídas
    const markAllAsRead = async () => {
        try {
            const token = localStorage.getItem('token');
            await api.put('/api/notifications/mark-as-read', {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchNotifications();
        } catch (error) {
            console.error('Error al marcar todas las notificaciones como leídas:', error);
        }
    };

    // Eliminar notificación individual
    const deleteNotification = async (notificationId) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/notifications/${notificationId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchNotifications();
        } catch (error) {
            console.error('Error al eliminar la notificación:', error);
        }
    };

    // Eliminar todas las notificaciones
    const deleteAllNotifications = async () => {
        try {
            const token = localStorage.getItem('token');
            await api.delete('/api/notifications/delete-all', {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchNotifications();
        } catch (error) {
            console.error('Error al eliminar todas las notificaciones:', error);
        }
    };

    const NotificationCheckbox = ({ notificationId, onMarkAsRead }) => {
        const [showTooltip, setShowTooltip] = useState(false);

        return (
            <div
                className="checkbox-container"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {showTooltip && (
                    <div className="custom-tooltip">
                        Marcar como leída
                    </div>
                )}
                <Checkbox
                    onClick={(e) => {
                        e.stopPropagation(); // Evitar que el evento haga clic en la notificación
                        onMarkAsRead(notificationId);
                    }}
                    className="custom-checkbox"
                    sx={{
                        padding: '0',
                        color: '#007bff',
                        '&.Mui-checked': { color: '#0056b3' },
                    }}
                />
            </div>
        );
    };

    return (
        <div className="notification-container" ref={dropdownRef}>
            <Tooltip title="Notificaciones">
                <div className="notification-bell" onClick={handleBellClick}>
                    <FaBell className={hasUnreadNotifications ? 'has-unread' : ''} />
                    {hasUnreadNotifications && <span className="notification-dot"></span>}
                </div>
            </Tooltip>

            {isDropdownOpen && (
                <div className="notification-dropdown">
                    {notifications.length === 0 ? (
                        <div className="no-notifications">No hay notificaciones</div>
                    ) : (
                        <>
                            <div className="actions">
                                <button onClick={markAllAsRead}>Marcar todas como leídas</button>
                                <button onClick={deleteAllNotifications}>Eliminar todas</button>
                            </div>
                            {notifications.map((notification) => (
                                <div
                                    key={notification._id}
                                    className={`notification-item ${notification.isRead ? 'read' : 'unread'}`}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    <p className={`notification-message ${notification.isRead ? 'text-gray' : ''}`}>
                                        {notification.message}
                                    </p>
                                    <div className="notification-actions">
                                        {!notification.isRead && (
                                            <NotificationCheckbox
                                                notificationId={notification._id}
                                                onMarkAsRead={markNotificationAsRead}
                                            />
                                        )}
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            deleteNotification(notification._id);
                                        }}>
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

//add prop validation for notification Id and onMarkAsRead
NotificationBell.propTypes = {
    notificationId: PropTypes.string.isRequired,
    onMarkAsRead: PropTypes.func.isRequired,
};

export default NotificationBell;
