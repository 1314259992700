import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import PaginatedForm from '../common/PaginatedForm';
import api from '../../api/axiosConfig';
import formRMEJson from '../../data/RMEForm.json';
import formSGCJson from '../../data/SGCForm.json';
import './FormModal.css'; // Asegúrate de tener estilos para ajustar la visualización de columnas

const FormModal = ({ establishmentId, onFormCreated, onClose, formToEdit, filteredForms }) => {
  const [selectedForm, setSelectedForm] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    if (formToEdit) {
      setSelectedForm(formToEdit);
    }
  }, [formToEdit]);

  const handleOptionClick = async (option) => {
    console.log('Formulario seleccionado:', option);
  
    if (option === 'Formulario RME') {
      setSelectedForm(formRMEJson);
    } else if (option === 'Formulario SGC') {
      setSelectedForm(formSGCJson);
    } else {
      const selectedFormData = filteredForms.find(form => form.title === option);
  
      if (selectedFormData) {
        setIsLoadingForm(true);
        try {
          const token = localStorage.getItem('authToken');
          const response = await api.get(`/api/custom-forms/${selectedFormData._id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSelectedForm(response.data);
        } catch (error) {
          console.error('Error al cargar el formulario:', error);
          toast.error('Hubo un problema al cargar el formulario.', { position: 'bottom-right', autoClose: 3000 });
        } finally {
          setIsLoadingForm(false);
        }
      }
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderFormButtons = () => {
    // Combinamos los formularios predefinidos y los filtrados
    const allForms = [
      { title: 'Formulario RME' },
      { title: 'Formulario SGC' },
      ...filteredForms,
    ];

    // Dividir en grupos de 2 para mostrar en dos columnas
    const rows = [];
    for (let i = 0; i < allForms.length; i += 2) {
      rows.push(allForms.slice(i, i + 2));
    }

    return (
      <div className="modal-buttons-grid">
        {rows.map((row, rowIndex) => (
          <div className="modal-button-row" key={rowIndex}>
            {row.map((form) => (
              <button
                data-testid={`form-button-${form.title}`}
                key={form.title}
                className="modal-button"
                onClick={() => handleOptionClick(form.title)}
              >
                {form.title}
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div data-testid="modal-overlay" className="modal-overlay" onClick={handleOverlayClick}>
      <div data-testid="modal-content" className="modal-content">
        {isLoadingForm ? (
          <p data-testid="loading-text">Cargando formulario...</p>
        ) : !selectedForm && !formToEdit ? (
          <div>
            <h2 data-testid="select-form-title">Selecciona el tipo de formulario</h2>
            {renderFormButtons()}
          </div>

        ) : (
          <PaginatedForm
            establishmentId={establishmentId}
            formJson={selectedForm}
            onClose={onClose}
            onFormCreated={onFormCreated}
            formToEdit={formToEdit}
          />
        )}
      </div>
    </div>
  );
};

FormModal.propTypes = {
  establishmentId: PropTypes.string.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formToEdit: PropTypes.object,
  filteredForms: PropTypes.array.isRequired,
};

export default FormModal;