import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../components/landing/Header";
import Footer from "../components/landing/Footer";
import api from '../api/axiosConfig';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/forgot-password', { email });
      toast.success(response.data.message, { position: 'bottom-right', autoClose: 3000 });
    } catch (error) {
      const message = error.response?.data?.message || 'Error al solicitar la recuperación. Inténtalo más tarde.';
      toast.error(message, { position: 'bottom-right', autoClose: 3000 });
    }
  };

  return (
    <div className="forgot-password-page">
      <Header />
      <div className="forgot-password-container">
        <div className="forgot-password-card">
          <h2>Recuperar Contraseña</h2>
          <p>Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
          <form onSubmit={handleSubmit}>
            <label>Correo Electrónico</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Ingresa tu correo"
            />
            <button type="submit">Enviar Enlace</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;