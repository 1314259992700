import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './ReceiptModal.css';
import api from '../../api/axiosConfig';
import downloadIcon from '../../assets/download-icon.gif';

const ReceiptModal = ({ visit, onClose }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');
  const [establishment, setEstablishment] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(userData.companyLogo || null);
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchEstablishment = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const establishmentId = typeof visit.establishmentId === 'object'
          ? visit.establishmentId._id
          : visit.establishmentId;

        if (!establishmentId) {
          console.error('No se encontró el establishmentId');
          return;
        }

        const response = await api.get(`/api/establishments/${establishmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEstablishment(response.data);
      } catch (error) {
        console.error('Error fetching establishment:', error);
      }
    };

    fetchUserProfile();

    if (visit && visit.establishmentId) {
      fetchEstablishment();
    }
  }, [visit]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Función para cargar la imagen y mantener la proporción
  const addImageToPDF = (doc, imageUrl, x, y, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = `${imageUrl}?cacheblock=${new Date().getTime()}`;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let finalWidth = maxWidth;
        let finalHeight = maxHeight;

        if (aspectRatio > 1) {
          finalHeight = Math.min(maxHeight, maxWidth / aspectRatio);
        } else {
          finalWidth = Math.min(maxWidth, maxHeight * aspectRatio);
        }

        doc.addImage(img, 'PNG', x, y, finalWidth, finalHeight);
        resolve();
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();

    try {
      if (companyLogo) {
        await addImageToPDF(doc, companyLogo, 10, 10, 40, 20);
      }

      doc.setFontSize(18);
      doc.setFont('helvetica', 'normal');
      doc.text('RECIBO', doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(`Nombre de Emisor:`, 10, 50);
      doc.setFont('helvetica', 'normal');
      doc.text(userName, 50, 50);

      doc.setFont('helvetica', 'bold');
      doc.text(`Matrícula #:`, 10, 60);
      doc.setFont('helvetica', 'normal');
      doc.text(licenseNumber, 35, 60);

      if (establishment) {
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text('Información del Establecimiento:', 10, 70);

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Nombre del Establecimiento:`, 10, 80);
        doc.setFont('helvetica', 'normal');
        doc.text(establishment.name, 70, 80);

        // Elimina la primera instancia de "Dirección:" para evitar duplicación
        doc.setFont('helvetica', 'bold');
        doc.text(`Dirección:`, 10, 90);
        doc.setFont('helvetica', 'normal');
        doc.text(
          `${establishment.address} ${establishment.addressNumber || ''}, ${establishment.city || ''}, ${establishment.province || ''}`,
          32, 90
        );

        doc.setFont('helvetica', 'bold');
        doc.text(`Industria:`, 10, 100);
        doc.setFont('helvetica', 'normal');
        doc.text(establishment.industry?.name || '', 30, 100);

        // Ajustar la posición de "Información de Contacto" para evitar superposición
        doc.setFont('helvetica', 'bold');
        doc.text(`Información de Contacto:`, 10, 110);
        doc.setFont('helvetica', 'normal');
        doc.text(establishment.contactInfo, 62, 110);
      } else {
        doc.text("Información del Establecimiento: No disponible", 10, 80);
      }

      // Método de Pago
      const paymentMethodText = typeof visit.paymentMethod === 'string' ? visit.paymentMethod : (visit.paymentMethod?.name || 'No disponible');

      // Agregar tabla de conceptos a pagar
      doc.autoTable({
        head: [['Fecha de Visita', 'Programada', 'Monto a Cobrar', 'Método de Pago']],
        body: [
          [
            new Date(visit.date).toLocaleDateString('es-ES'),
            visit.type === 'Programada' ? 'Sí' : 'No',
            `$${visit.visitRate.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            paymentMethodText,
          ],
        ],
        startY: 130,
      });

      doc.save(`Recibo-${visit._id}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div data-testid="receipt-modal-overlay" className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" data-testid="receipt-modal-content">
        <div className="receipt-header" data-testid="receipt-header">
          {companyLogo && (
            <img
              data-testid="company-logo"
              src={companyLogo}
              alt="Logo de la Empresa"
              className="company-logo"
            />
          )}
          <h2 className="receipt-title" data-testid="receipt-title">RECIBO</h2>
        </div>

        <div className="receipt-info">
          <div className="receipt-left" data-testid="receipt-emitter-info">
            <p><strong>Nombre de Emisor:</strong> {userName}</p>
            <p><strong>Matrícula #:</strong> {licenseNumber}</p>
          </div>
          <div className="receipt-right">
            <p><strong>Fecha de Visita:</strong> {new Date(visit.date).toLocaleDateString('es-ES')}</p>
            <p><strong>Número de Recibo:</strong> {visit._id}</p>
          </div>
        </div>

        <h3>Cliente</h3>
        <div className="establishment-section" data-testid="establishment-info">

          <div>
            <p><strong>Nombre del Establecimiento:</strong> <br /> {establishment?.name || 'No disponible'}</p>
            <p><strong>Dirección:</strong>  <br />  {establishment ? `${establishment.address}, ${establishment.addressNumber} ` : 'No disponible'}</p>
            <p><strong>Barrio:</strong>  <br />  {establishment?.neighborhood || 'No disponible'}</p>
          </div>
          <div>
            <p><strong>Industria:</strong>  <br />  {establishment?.industry?.name || 'No disponible'}</p>
            <p><strong>Información de Contacto:</strong>  <br />  {establishment?.contactInfo || 'No disponible'}</p>
          </div>
        </div>

        <div className="concepts-to-pay" data-testid="concepts-to-pay">
          <h3>Conceptos a Pagar</h3>
          <table>
            <thead>
              <tr>
                <th>Fecha de Visita</th>
                <th>Programada</th>
                <th>Monto a Cobrar</th>
                <th>Método de Pago</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{new Date(visit.date).toLocaleDateString('es-ES')}</td>
                <td>{visit.type === 'Programada' ? 'Sí' : 'No'}</td>
                <td>${visit.visitRate.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{visit.paymentMethod}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="important-note" data-testid="important-note">
          <p><strong>IMPORTANTE:</strong> Este recibo no es una factura legal válida, solo un aviso informativo.</p>
        </div>

        <button className="download-button" onClick={handleDownloadPDF} data-testid="download-pdf-button">
          <img src={downloadIcon} alt="Ícono de descarga" className="download-icon" />
          Descargar PDF
        </button>
        <button className="close-button" onClick={onClose} data-testid="close-button">Cerrar</button>
      </div>
    </div>
  );
};

ReceiptModal.propTypes = {
  visit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReceiptModal;