import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import profileIcon from '../../assets/profile-icon.png';
import settingsIcon from '../../assets/preferences-icon.png';
import logoutIcon from '../../assets/logout-icon.png';
import hamburgerIcon from '../../assets/hamburger-icon.png';
import BioSafeAppIcon from '../../assets/landingPage/icon512.png';
import AddVisitModal from '../visits/AddVisitModal';
import NotificationBell from '../common/NotificationBell'; // Importa el nuevo componente de la campana de notificación

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.hamburger-button')) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddVisitClick = () => {
    setShowVisitModal(true);
  };

  const handleCloseModal = () => {
    setShowVisitModal(false);
  };

  const handleVisitAdded = (establishmentId) => {
    const establishmentVisitsPath = `/dashboard/establecimiento/${establishmentId}/visits`;
    if (location.pathname === establishmentVisitsPath) {
      navigate(`${establishmentVisitsPath}?updated=${Date.now()}`);
    } else {
      navigate(establishmentVisitsPath);
    }
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('¿Seguro que deseas cerrar sesión?');
    if (confirmLogout) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userName');
      navigate('/login');
    }
  };

  return (
    <header className="main-header">
      <div className="header-left">
        <a href="/">
          <div className="logo">
            <img src={BioSafeAppIcon} alt="BioSafeApp" />
            <h1 style={{color:'white'}}>BioSafeApp</h1>
          </div>
        </a>
        <button className="add-visit-button" onClick={handleAddVisitClick}>
          Agregar Visita
        </button>
      </div>
      <div className="header-right">
        {/* Campana de Notificación */}
        <NotificationBell />

        <button className="hamburger-button" onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="Menu" />
        </button>

        {menuOpen && (
          <div className="hamburguer-dropdown-menu">
            <Link to="/profile" className="dropdown-item" onClick={closeMenu}>
              <img src={profileIcon} alt="Perfil" className="dropdown-icon" />
              Perfil
            </Link>
            <Link to="/settings" className="dropdown-item" onClick={closeMenu}>
              <img src={settingsIcon} alt="Preferencias" className="dropdown-icon" />
              Configuración
            </Link>
            <div className="dropdown-item logout" onClick={handleLogout}>
              <img src={logoutIcon} alt="Cerrar sesión" className="dropdown-icon" />
              Cerrar sesión
            </div>
          </div>
        )}
      </div>

      {showVisitModal && (
        <AddVisitModal
          onClose={handleCloseModal}
          onVisitAdded={handleVisitAdded}
        />
      )}
    </header>
  );
};

export default Header;