import React from "react";
import "./Footer.css";
import BioSafeAppIcon from "../../assets/landingPage/icon512.png"; // Ajusta la ruta según tu proyecto
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={BioSafeAppIcon} alt="BioSafeApp" className="footer-logo-image" />
          <span className="footer-logo-text">BioSafeApp</span>
        </div>
        <p className="footer-text">
          © BioSafeApp fue desarrollado por Atenea Conocimientos.
        </p>
        <div className="footer-socials">
          <a href="https://instagram.com/atenea.conocimientos/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/69496944/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>
          <a href="https://www.youtube.com/@AteneaConocimientos" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
