import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/axiosConfig';
import './PeriodicVisitsModal.css';

const PeriodicVisitsModal = ({ isOpen, onRequestClose, establishmentId, refreshNextVisit }) => {
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [dayOfMonth, setDayOfMonth] = useState('');
  const [daysBeforeNotification, setDaysBeforeNotification] = useState(5);
  const [periodicVisitExists, setPeriodicVisitExists] = useState(false);

  const fetchPeriodicVisit = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${establishmentId}/periodicVisit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.months) {
        const { months, dayOfMonth, daysBeforeNotification } = response.data;

        setSelectedMonths(months || []);
        setDayOfMonth(dayOfMonth || '');
        setDaysBeforeNotification(daysBeforeNotification || 5);
        setPeriodicVisitExists(true);
      } else {
        // Si no se encuentra una visita periódica, se considera que no existe y permitimos crear una nueva
        setSelectedMonths([]);
        setDayOfMonth('');
        setDaysBeforeNotification(5);
        setPeriodicVisitExists(false); // Actualizamos para que el método sea un POST en lugar de PUT
      }

    } catch (error) {
      console.error('Error al obtener la visita periódica:', error);
      setPeriodicVisitExists(false); // También en caso de error, permitimos que el usuario cree una nueva
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchPeriodicVisit();
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    if (selectedMonths.length === 0) {
      const confirmDelete = window.confirm('Este cambio dejará al establecimiento sin visitas periódicas. ¿Estás de acuerdo?');
      if (confirmDelete) {
        try {
          const token = localStorage.getItem('authToken');
          await api.delete(`/api/establishments/${establishmentId}/periodicVisit`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          refreshNextVisit(); // Refresca el calendario
          onRequestClose();
        } catch (error) {
          console.error('Error al eliminar las visitas periódicas:', error);
        }
      }
    } else {
      try {
        const token = localStorage.getItem('authToken');
        const endpoint = `/api/establishments/${establishmentId}/periodicVisit`;
        const method = periodicVisitExists ? 'put' : 'post';
  
        await api[method](endpoint, {
          months: selectedMonths,
          dayOfMonth: Number(dayOfMonth),
          daysBeforeNotification: Number(daysBeforeNotification),
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        refreshNextVisit(); // Refresca el calendario
        onRequestClose();
      } catch (error) {
        console.error('Error al guardar la visita periódica:', error);
      }
    }
  };

  const toggleMonth = (month) => {
    setSelectedMonths((prevSelectedMonths) =>
      prevSelectedMonths.includes(month)
        ? prevSelectedMonths.filter((m) => m !== month)
        : [...prevSelectedMonths, month]
    );
  };

  return (
    <div data-testid="periodic-visit-modal" className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2 data-testid="modal-title">Configurar Visitas Periódicas</h2>
        <div className="months-container">
          <h3>Seleccionar Meses</h3>
          <div className="months-grid">
            {['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'].map((month) => (
              <button
                key={month}
                data-testid={`month-button-${month}`}
                className={selectedMonths.includes(month) ? 'selected' : ''}
                onClick={() => toggleMonth(month)}
              >
                {month}
              </button>
            ))}
          </div>
        </div>

        <div className="date-notification-container">
          <div className="day-of-month">
            <label>Día del Mes</label>
            <select data-testid="day-of-month-select" value={dayOfMonth} onChange={(e) => setDayOfMonth(e.target.value)}>
              {[...Array(31).keys()].map((day) => (
                <option key={day} value={day + 1}>
                  {day + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="days-before-notification">
            <label>Días Antes para Notificación</label>
            <select data-testid="days-before-notification-select" value={daysBeforeNotification} onChange={(e) => setDaysBeforeNotification(e.target.value)}>
              {[1, 2, 3, 5, 7, 10, 14].map((days) => (
                <option key={days} value={days}>
                  {days}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="modal-actions">
          <button data-testid="submit-button" onClick={handleSubmit}>
            {periodicVisitExists ? 'Actualizar Configuración' : 'Guardar Configuración'}
          </button>
          <button data-testid="cancel-button" onClick={onRequestClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

PeriodicVisitsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  establishmentId: PropTypes.string.isRequired,
  refreshNextVisit: PropTypes.func.isRequired,
};

export default PeriodicVisitsModal;
