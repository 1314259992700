import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedAdminRoute = ({ children }) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    console.error('Token no encontrado. Redirigiendo al dashboard.');
    return <Navigate to="/dashboard" />;
  }

  let userRole = null;
  console.log('userRole:', userRole);
  try {
    userRole = JSON.parse(atob(token.split('.')[1])).role; // Decodifica el payload del token
  } catch (error) {
    console.error('Error al decodificar el token:', error);
    return <Navigate to="/dashboard" />;
  }

  if (userRole !== 'admin') {
    console.warn('Acceso denegado. Usuario no es administrador.');
    return <Navigate to="/dashboard" />;
  }

  return children;
};

ProtectedAdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedAdminRoute;