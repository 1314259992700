import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dashboardIcon from '../../assets/dashboard.png';
import establishmentsIcon from '../../assets/establecimientos.png';
import customFormsIcon from '../../assets/custom-forms-icon.png'; // Agregamos el ícono personalizado
import suggestedRoutesIcon from '../../assets/view-routes-icon.png'; // Agregamos el ícono personalizado
import collapsedIcon from '../../assets/collapsed.png';
import extendedIcon from '../../assets/extended.png';
import Tooltip from '@mui/material/Tooltip';
import './Sidebar.css';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <button data-testid="toggle-sidebar-btn" onClick={toggleSidebar} className="toggle-btn">
        <img
          src={isCollapsed ? extendedIcon : collapsedIcon}
          alt={isCollapsed ? 'Expandir Sidebar' : 'Colapsar Sidebar'}
          style={{ width: '20px', height: '20px' }}
        />
      </button>
      <ul>
        <Tooltip title="Panel de Control">
          <li data-testid="dashboard-link" onClick={() => handleNavigation('/dashboard')}>
            <img src={dashboardIcon} alt="Dashboard" />
            <span data-testid="dashboard-text">Panel de control</span>
          </li>
        </Tooltip>
        <Tooltip title="Establecimientos">
          <li data-testid="establishments-link" onClick={() => handleNavigation('/dashboard/establecimientos')}>
            <img src={establishmentsIcon} alt="Establecimientos" />
            <span>Establecimientos</span>
          </li>
        </Tooltip>
        <Tooltip title="Formularios Personalizados">
          <li className='long-span'data-testid="custom-forms-link" onClick={() => handleNavigation('/dashboard/custom-forms')}>
            <img src={customFormsIcon} alt="Formularios Personalizados" />
            <span >Formularios Personalizados</span>
          </li>
        </Tooltip>
        <Tooltip title="Ver rutas sugeridas para tus visitas">
          <li data-testid="suggested-routes-link" onClick={() => handleNavigation('/dashboard/suggested-routes')}>
            <img src={suggestedRoutesIcon} alt="Formularios Personalizados" />
            <span>Planificador de Rutas</span>
          </li>
        </Tooltip>
      </ul>
    </div>
  );
};

export default Sidebar;